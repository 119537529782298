

@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

[data-theme="yellow"] {
  --primary-dark: #ffd32b;
  --primary-light: #eab01c;
}
[data-theme="blue"] {
  --primary-dark: #192f59;
  --primary-light: #c41c20;
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.65;
  font-style: normal;
  color: #29281e;
  font-family: Barlow, sans-serif !important;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: default !important;
  font-display: auto;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}
.page_wrapper {
  overflow: hidden;
  position: relative;
}
[disabled] {
  opacity: 0.4;
  pointer-events: none;
}
iframe {
  border: none;
}
a:active,
a:focus,
input,
input:active,
input:focus,
input:hover,
textarea,
textarea:active,
textarea:focus,
textarea:hover {
  outline: 0;
  cursor: pointer !important;
}
input[type="search"],
input[type="text"] {
  -webkit-appearance: none;
  cursor: pointer !important;
}
embed,
img:not([draggable]),
object,
video {
  height: auto;
  max-width: 100%;
  cursor: pointer !important;
}
img {
  border: none;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
a {
  outline: 0;
  display: inline-block;
  text-decoration: none;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  cursor: pointer !important;
}
a:active,
a:focus,
a:hover,
a:visited {
  outline: 0;
  text-decoration: none;
}
button {
  padding: 0;
  border: none;
  outline: 0;
  background: 0 0;
  display: inline-block;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  cursor: pointer !important;
}
button:focus {
  outline: 0;
  cursor: pointer !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
hr {
  opacity: 1;
  margin: 25px 0;
  background-color: #d2d2d2;
}
.fa {
  font-family: "Font Awesome 5 Brands";
}
.container {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  margin: -15px;
}
.col {
  flex: unset;
  padding: 15px;
}
.dropdown-menu {
  border: none;
  margin-top: 20px;
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
  animation: 0.3s ease-in-out 0s normal none 1 running fadeInUp;
}
.dropdown-menu:before {
  left: 0;
  right: 0;
  top: -20px;
  content: "";
  height: 20px;
  display: block;
  position: absolute;
}
.dropdown-toggle::after {
  margin: 0;
  border: none;
  line-height: 1;
  content: "";
  font-weight: 400;
  color: var(--primary-dark);
  font-family: "Font Awesome 5 Pro";
}
.layer {
  position: relative !important;
}
.bg_light {
  background-color: #f6f6f6;
}
.bg_light_2 {
  background-color: #f8f8f8;
}
.bg_dark {
  background-color: #1f1d0d;
}
.bg_primary {
  background-color: var(--primary-light);
}
.bg_facebook {
  background-color: #3b5998;
}
.bg_twitter {
  background-color: #1da1f2;
}
.bg_instagram {
  background-color: #c32aa3;
}
.bg_linkedin {
  background-color: #0a66c2;
}
@keyframes dropDown {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.backtotop .scroll {
  z-index: 1;
  width: 60px;
  height: 60px;
  display: flex;
  font-size: 30px;
  font-weight: 400;
  position: relative;
  align-items: center;
  border-radius: 100%;
  color: #fff;
  justify-content: center;
  background-color: #25d366;
  box-shadow: 0 10px 33.25px 1.75px rgba(0, 0, 0, 0.15);
}
.decoration_wrap {
  z-index: 1;
  position: relative;
}
.decoration_wrap .deco_item {
  z-index: -1;
  position: absolute;
}
.decoration_wrap .overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: absolute;
}
.decoration_wrap .half_bg_top {
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  position: absolute;
}
.section_space_lg {
  padding-top: 120px;
  padding-bottom: 120px;
}
.section_space_md {
  padding-top: 70px;
  padding-bottom: 70px;
  
}
.btn_wrap {
  padding: 44px 0;
}
.btn {
  border: none;
  line-height: 1;
  padding: 0 40px;
  overflow: hidden;
  position: relative;
  color: #fff;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  border: 2px solid transparent;
  border-radius: 8px;
}
.btn span {
  position: relative;
  display: inline-block;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.btn span small {
  display: block;
  padding: 16px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.btn span small:nth-child(2) {
  left: 50%;
  top: 100%;
  opacity: 0;
  width: 100%;
  position: absolute;
  transform: translateX(-50%);
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn:hover {
  z-index: 1;
  color: #fff;
  transform: translateY(-4px);
  box-shadow: 0 8px 24px 0 rgba(16, 18, 19, 0.4);
}
.btn:hover span small {
  color: #fff;
}
.btn:hover span small:nth-child(1) {
  opacity: 0;
  transform: translateY(-100%);
}
.btn:hover span small:nth-child(2) {
  top: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
}
.btn.btn_small {
  padding: 0 28px;
}
.btn.btn_small span small {
  padding: 9px 0;
}
.btn_primary {
  color: #1f1d0d;
  border-color: var(--primary-light);
  background-color: var(--primary-light);
}
.btn_primary:hover {
  color: #1f1d0d;
  box-shadow: 0 8px 24px 0 rgba(247, 255, 98, 0.4);
}
.btn_primary:hover span small {
  color: #1f1d0d;
}
.btn_dark {
  color: #fff;
  border-color: #1f1d0d;
  background-color: #1f1d0d;
}
.btn_dark:hover {
  color: #fff;
}
.btn_dark:hover span small {
  color: #fff;
}
.btn_warning {
  color: #1f1d0d;
  border-color: var(--primary-dark);
  background-color: var(--primary-dark);
}
.btn_warning:hover {
  color: #1f1d0d;
  box-shadow: 0 8px 24px 0 rgba(255, 211, 43, 0.5);
}
.btn_warning:hover span small {
  color: #1f1d0d;
}
.btn.border_dark {
  color: #1f1d0d;
  border-color: #1f1d0d;
}
.btn.border_dark:hover {
  color: #fff;
  background-color: #1f1d0d;
}
.btn.border_dark:hover span small {
  color: #fff;
}
.btn_unfill {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1f1d0d;
  align-items: center;
  display: inline-flex;
  font-family: Barlow, sans-serif;
}
.btn_unfill .btn_icon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  margin-left: 10px;
  position: relative;
  border-radius: 100%;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: #1f1d0d;
}
.btn_unfill .btn_icon i {
  top: 50%;
  font-size: 14px;
  position: absolute;
  color: var(--primary-light);
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.btn_unfill .btn_icon i:nth-child(1) {
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn_unfill .btn_icon i:nth-child(2) {
  left: -100%;
  transform: translateY(-50%);
}
.btn_unfill:hover {
  color: #1f1d0d;
}
.btn_unfill:hover span {
  text-decoration: underline;
}
.btn_unfill:hover .btn_icon {
  transform: translateX(2px);
}
.btn_unfill:hover .btn_icon i:nth-child(1) {
  left: 100%;
  transform: translateY(-50%);
}
.btn_unfill:hover .btn_icon i:nth-child(2) {
  left: 50%;
  transform: translate(-50%, -50%);
}
.unordered_list,
.unordered_list_center,
.unordered_list_end {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.unordered_list > li,
.unordered_list_center > li,
.unordered_list_end > li {
  float: left;
  list-style: none;
  display: inline-block;
}
.unordered_list {
  justify-content: flex-start;
}
.unordered_list_center {
  justify-content: center;
}

.unordered_list_block {
  margin: 0;
  padding: 0;
  display: block;
}
.unordered_list_block > li {
  display: block;
  list-style: none;
}
ol[type="1"] > li:not(:last-child) {
  margin-bottom: 12px;
}
.info_list.unordered_list_block > li:not(:last-child) {
  margin-bottom: 12px;
}
.info_list a {
  display: block;
  color: #29281e;
}
.info_list a:hover {
  opacity: 0.7;
}

.info_list i {
  float: left;
  font-size: 12px;
  margin: 5px 10px 0 0;
  color: var(--primary-light);
  text-shadow: 2px 2px 0 #1f1d0d;
}
[dir="rtl"] .info_list i {
  float:right;
  margin: 5px 0 0 10px;
}
.info_list i[class*="check"] {
  font-size: 14px;
  text-shadow: none;
  color: var(--primary-dark);
}
.info_list span {
  display: table;
}
.page_list.unordered_list {
  margin: -5px -10px;
}
.page_list.unordered_list > li {
  padding: 5px 10px;
}
.page_list.unordered_list_block > li:not(:last-child) {
  margin-bottom: 10px;
}
.page_list > a {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
[dir="rtl"] .page_list .item_icon {
  margin-left: 10px;
  margin-right:0px;
  color: var(--primary-light);
}
.page_list .item_icon {
  margin-right: 10px;
  margin-left: 0px;
  color: var(--primary-light);
}
.tags_list {
  margin: -10px;
}
.tags_list > li {
  padding: 10px;
}
.tags_list a {
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #1f1d0d;
  padding: 12px 16px;
  text-transform: uppercase;
  font-family: Barlow, sans-serif;
  border-radius: 8px;
  background-color: #fff;
}
.tags_list a:hover {
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 0 0 #1f1d0d;
}
.tags_list.style_2 a {
  background-color: #f6f6f6;
}
.item_category_list {
  overflow: hidden;
  border-radius: 8px;
}

.item_category_list span:hover {
  background-color: var(--primary-dark);
}
.meta_info_list {
  margin: -2px -6px;
}
.meta_info_list > li {
  font-size: 14px;
  padding: 2px 6px;
  line-height: 20px;
}
.meta_info_list span {
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  font-weight:500;
  font-size: 14px;
}
.meta_info_list i {
  margin-right: 2px;
  color: var(--primary-dark);
}
.meta_info_list a {
  color: #1f1d0d;
}
.meta_info_list a:hover span {
  text-decoration: underline;
  color: #1f1d0d;
}
.meta_info_list.unordered_list_block > li:not(:last-child) {
  margin-bottom: 8px;
}
.section_heading {
  margin-bottom: 40px;
}
.section_heading .heading_text {
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 24px;
}
.section_heading .heading_description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
blockquote {
  display: flex;
  margin-bottom: 20px;
}
blockquote .blockquote_icon {
  flex: 0 0 60px;
  margin-right: 30px;
}
blockquote p {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
  font-family: Barlow, sans-serif;
}
.slick-dots {
  margin: 0;
  display: flex;
  position: static;

  align-items: center;
  justify-content: center;
}
.slick-dots li {
  width: auto;
  height: auto;
  margin: 0 5px;
  display: inline-block;
}
.slick-dots li button {
  margin: 0;
  padding: 0;
  opacity: 0.3;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 100%;
  background-color: #6a6f08;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.slick-dots li button:before {
  display: none;
}
.slick-dots li.slick-active button,
.slick-dots li:hover button {
  opacity: 1;
}
.slick-dotted.slick-slider {
  margin-bottom: 0;
}
.carousel_arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel_arrow button {
  width: 40px;
  height: 40px;
  overflow: hidden;
  position: relative;
  color: #1f1d0d;
  border-radius: 100%;
  background: #fff;
  border: 1px solid #d2d2d2;
}
.carousel_arrow button:not(:last-child) {
  margin-right: 8px;
}
.carousel_arrow button i {
  top: 50%;
  font-size: 16px;
  margin-top: 1px;
  position: absolute;
  color: #1f1d0d;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.carousel_arrow button:nth-child(1) i:nth-child(1) {
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel_arrow button:nth-child(1) i:nth-child(2) {
  left: 110%;
  transform: translateY(-50%);
}
.carousel_arrow button:nth-child(2) i:nth-child(1) {
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel_arrow button:nth-child(2) i:nth-child(2) {
  left: -110%;
  transform: translateY(-50%);
}
.carousel_arrow button:hover {
  color: #fff;
  border-color: #1f1d0d;
  background-color: #1f1d0d;
}
.carousel_arrow button:hover:nth-child(1) i:nth-child(1) {
  left: -110%;
  transform: translateY(-50%);
}
.carousel_arrow button:hover:nth-child(1) i:nth-child(2) {
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel_arrow button:hover:nth-child(2) i:nth-child(1) {
  left: 110%;
  transform: translateY(-50%);
}
.carousel_arrow button:hover:nth-child(2) i:nth-child(2) {
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel_arrow button:hover i {
  color: var(--primary-light);
}
.arrow_top_right {
  position: relative;
}
.arrow_top_right .carousel_arrow {
  right: 0;
  top: -61px;
  z-index: 1;
  position: absolute;
}
.arrow_right_left {
  position: relative;
}
.arrow_right_left .carousel_arrow button {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.arrow_right_left .carousel_arrow button:nth-child(1) {
  left: 0;
}
.arrow_right_left .carousel_arrow button:nth-child(2) {
  right: 0;
}
.image_widget {
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  box-shadow: -20px 20px 0 0 var(--primary-dark);
}
.mfp-iframe-holder .mfp-content {
  width: 100%;
  line-height: 0;
  max-width: 70%;
}
.mfp-container {
  padding: 0 15px;
}

@media screen and (max-width: 1199px) {
  .mfp-iframe-holder .mfp-content {
    max-width: 100%;
  }
}
.video_play_btn {
  display: flex;
  align-items: center;
}

.video_play_btn .icon i {
  margin-left: 4px;
}
.video_play_btn .icon:hover {
  color: #1f1d0d;
  border-color: var(--primary-dark);
  background-color: var(--primary-dark);
}
.video_play_btn .text {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  font-family: Barlow, sans-serif;
}
.video_play_btn.text-white .icon {
  color: #fff;
  border: 2px solid #fff;
}
.video_play_btn.text-white .icon:hover {
  color: #1f1d0d;
  border-color: var(--primary-dark);
  background-color: var(--primary-dark);
}
.video_play_btn.text-white .text {
  color: #fff;
}
.intro_video_section .shape_img_1 {
  top: -15px;
  right: -147px;
  max-width: 102px;
}
.intro_video_section .shape_img_2 {
  top: 30%;
  left: -120px;
  max-width: 270px;
}
.intro_video_section .shape_img_3 {
  right: -220px;
  bottom: -200px;
  max-width: 497px;
}
.intro_video {
  margin-bottom: 50px;
}
.intro_video .video_wrap {
  z-index: 1;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.intro_video .video_wrap .video_play_btn {
  top: 50%;
  left: 50%;
  z-index: 2;
  position: absolute;
  transform: translate(-50%, -50%);
}
.form_item {
  position: relative;
  margin-bottom: 12px;
}
.form_item input,
.form_item select,
.form_item textarea {
  width: 100%;
  outline: 0;
  display: block;
  box-shadow: none;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  border-radius: 8px;
  border: 2px solid #d2d2d2;
}
.form_item input:focus,
.form_item select:focus,
.form_item textarea:focus {
  outline: 0;
  box-shadow: none;
}
.form_item input {
  height: 40px;
  padding: 0 20px;
}
.form_item textarea {
  min-height: 80px;
  padding: 15px 20px;
}
.form_item select {
  height: 40px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: 12px;
  padding: 0 40px 0 20px;
  color: rgba(31, 29, 13, 0.5);
  background-repeat: no-repeat;
  background-position: right 20px center;
  /* background-image: url(../public/assets/images/icon/icon_down.png); */
}
.form_item:has(.btn) {
  display: flex;
  margin-bottom: 60px;
}
.form_item:has(.btn) input {
  flex: 0 0 370px;
  margin-right: 20px;
  border-color: #1f1d0d;
}
.input_title {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #1f1d0d;
  margin-bottom: 6px;
  font-family: Barlow, sans-serif;
}
:-moz-placeholder {
  color: rgba(31, 29, 13, 0.5);
}
::-moz-placeholder {
  color: rgba(31, 29, 13, 0.5);
}
:-ms-input-placeholder {
  color: rgba(31, 29, 13, 0.5);
}
::-webkit-input-placeholder {
  color: rgba(31, 29, 13, 0.5);
}
.checkbox_item {
  position: relative;
  padding-left: 26px;
  margin-bottom: 30px;
}
.checkbox_item input {
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  border-radius: 8px;
  border: 1px solid #1f1d0d;
  background-color: #fff;
}
.checkbox_item input:before {
  top: 50%;
  left: 50%;
  opacity: 0;
  line-height: 1;
  font-size: 8px;
  content: "";
  font-weight: 700;
  position: absolute;
  transform: translate(-50%, -50%);
  font-family: "Font Awesome 5 Pro";
}
.checkbox_item input:checked {
  background-color: var(--primary-light);
}
.checkbox_item input:checked:before {
  opacity: 1;
}
.checkbox_item label {
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
}

.tabs_wrapper .nav {
  margin: -10px -10px 30px;
}
.tabs_wrapper .nav > li {
  padding: 10px;
}
.tabs_wrapper button {
  color: #1f1d0d;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 16px;
  text-transform: uppercase;
  font-family: Barlow, sans-serif;
  border-radius: 8px;
  border: 2px solid #d2d2d2;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.tabs_wrapper button i {
  margin-right: 4px;
}
.tabs_wrapper button.active,
.tabs_wrapper button:hover {
  color: #fff;
  border-color: #1f1d0d;
  background-color: #1f1d0d;
}
.social_links {
  margin: -6px;
}
.social_links > li {
  padding: 6px;
}
.social_links a {
  z-index: 1;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}
.social_links a:after,
.social_links a:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: -1;
  position: absolute;
  border-radius: 100%;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.social_links a:before {
  border: 2px solid var(--primary-dark);
}
.social_links a:after {
  background-color: var(--primary-dark);
}
.social_links a i {
  color: #1f1d0d;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.social_links a:hover:before {
  transform: scale(1.1);
}
.social_links a:hover:after {
  transform: scale(0.8);
}
.counter_item {
  padding: 0 34px 24px 0;
  border-bottom: 1px solid #d2d2d2;
}
.counter_item .counter_value {
  font-size: 65px;
  font-weight: 800;
  line-height: 68px;
  margin-bottom: 10px;
  color:#eab01c;
}
.counter_item p {
  font-size: 16px;
  line-height: 24px;
}
.counter_section.bg_primary .counter_item {
  border-bottom: 1px solid #29281e;
}
.rating_wrap {
  display: flex;
  align-items: center;
}
.rating_wrap .review_counter {
  font-size: 12px;
  margin-left: 5px;
}
.rating_star li {
  line-height: 1;
  font-size: 12px;
}
.rating_star li:not(:last-child) {
  margin-right: 2px;
}
.rating_star li.active {
  color: #ffda47;
}
.iconbox_item .serial_number {
  font-size: 65px;
  font-weight: 800;
  line-height: 68px;
  font-family: Barlow, sans-serif;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1f1d0d;
}
.iconbox_item hr {
  margin: 15px 0 14px;
}
.iconbox_item .title_wrap {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
/* .iconbox_item .item_icon {
  width: 50px;
  height: 50px;
  padding: 8px;
  flex: 0 0 50px;
  line-height: 1;
  font-size: 18px;
  position: relative;
  margin-right: 18px;
  display: inline-flex;
  align-items: flex-end;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 3px 3px 0 0 var(--primary-dark);
} */
.iconbox_item .item_icon.bg_dark {
  align-items: center;
  color: var(--primary-dark);
  justify-content: center;
  background-color: #1f1d0d;
}
.iconbox_item .item_title {
  font-size: 22px;
  line-height: 25px;
}
.iconbox_item p {
  font-size: 14px;
  line-height: 22px;
}
.iconbox_item p a{
  color:inherit;
}
.iconbox_item p a:hover{
  color:#000;
}
.accordion .accordion-item {
  border: none;
  padding: 30px 0;
  border-radius: 0;
  position: relative;
  background-color: transparent;
  border-bottom: 1px solid #d2d2d2;
}
.accordion .accordion-button {
  padding: 0;
  font-size: 28px;
  font-weight: 700;
  box-shadow: none;
  line-height: 32px;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
  background-color: transparent;
}

.accordion .accordion-button:not(.collapsed)::after {
  content: "";
  transform: rotate(0);
}
.accordion .accordion-body {
  padding: 20px 0 0;
}
.accordion p {
  font-size: 16px;
  line-height: 24px;
}
.accordion .completed_progress {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}
.accordion .checkbox_item {
  margin: 0;
}
.accordion .checkbox_item:not(:last-child) {
  margin-bottom: 20px;
}
.accordion .checkbox_item label {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1f1d0d;
}
.accordion .accordion-item:has(.accordion_item_checked) {
  padding-left: 30px;
}
.accordion
  .accordion-item:has(.accordion_item_checked)
  .accordion_item_checked {
  left: 0;
  top: 47px;
  margin: 0;
  padding: 0;
  z-index: 2;
  position: absolute;
}
.accordion
  .accordion-item:has(.accordion_item_checked)
  .accordion_item_checked
  input {
  display: block;
  position: static;
}
.accordion.style_2 {
  padding: 0 30px;
  border-radius: 8px;
  border: 1px solid #d2d2d2;
}
.accordion.style_2 .accordion-item {
  border: none;
  padding: 30px 0;
}
.accordion.style_2 .accordion-item:not(:last-child) {
  border-bottom: 1px solid #d2d2d2;
}
.accordion.style_2 .accordion-button {
  font-size: 22px;
  line-height: 25px;
}
.accordion.style_2 .accordion-button:after {
  width: auto;
  height: auto;
  font-size: 24px;
  line-height: 1;
  border-radius: 0;
  background: 0 0;
  color: #1f1d0d;
  margin: -16px 0 0 auto;
}

[dir="rtl"] .accordion.style_2 .accordion-button:after {
  margin: -16px auto 0 0;
}
.accordion.style_2 .accordion-item:has(.accordion_item_checked) {
  padding-left: 25px;
}
.accordion.style_2
  .accordion-item:has(.accordion_item_checked)
  .accordion_item_checked {
  top: 35px;
}
.pagination_wrap {
  padding-top: 50px;
}
.pagination_nav {
  margin: -6px;
}
.pagination_nav li {
  padding: 6px;
}
.pagination_nav a {
  height: 48px;
  display: block;
  min-width: 48px;
  font-size: 16px;
  font-weight: 700;
  line-height: 46px;
  color: #1f1d0d;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #d2d2d2;
}
.pagination_nav li.active a,
.pagination_nav li:hover a {
  color: #fff;
  border-color: #1f1d0d;
  background-color: #1f1d0d;
}
.deals_countdown {
  display: flex;
  align-items: center;
}
.deals_countdown .discount_value {
  padding: 10px;
  height: 110px;
  min-width: 130px;
  margin-left: 28px;
  color: #1f1d0d;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--primary-light);
}
.deals_countdown .discount_value strong {
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  font-family: Barlow, sans-serif;
}
.deals_countdown .discount_value span {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.deals_countdown .countdown_timer {
  display: inline-flex;
}
.countdown_timer {
  padding: 9px 10px;
  text-align: center;
  justify-content: center;
  border: 1px solid #1f1d0d;
  border-radius: 8px;
  background-color: #fff;
}
.countdown_timer > li {
  padding: 10px;
  min-width: 90px;
  position: relative;
}
.countdown_timer > li:not(:last-child):after {
  top: 5px;
  right: -9px;
  content: ":";
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  position: absolute;
  font-family: Barlow, sans-serif;
}
.countdown_timer strong {
  display: block;
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  font-family: Barlow, sans-serif;
}
.countdown_timer span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.recent_comments_list li {
  color: #29281e;
}
.recent_comments_list li:not(:last-child) {
  margin-bottom: 10px;
}
.recent_comments_list li a {
  color: #1f1d0d;
}
.recent_comments_list li a:hover strong {
  text-decoration: underline;
}
.comments_list > li:not(:last-child) {
  margin-bottom: 30px;
}
.comments_list .comments_list > li {
  padding: 5px 0 0 30px;
}
.comment_item {
  padding: 30px;
  position: relative;
  border-radius: 8px;
  background-color: #f6f6f6;
}
.comment_item .comment_author {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.comment_item .author_thumbnail {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 20px;
}
.comment_item .author_name {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
}
.comment_item .comment_date {
  display: block;
  font-size: 14px;
  line-height: 20px;
}
.comment_item p {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 24px;
}
.comment_item .reply_btn {
  top: 30px;
  right: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  position: absolute;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.comment_item .reply_btn i {
  margin-left: 5px;
}
.comment_item .reply_btn:hover {
  opacity: 0.6;
}
.comment_form_wrap .form_item input,
.comment_form_wrap .form_item textarea {
  border-width: 1px;
  background-color: #f6f6f6;
}
.comment_form_wrap .form_item input:focus,
.comment_form_wrap .form_item textarea:focus {
  background-color: #fff;
}
.comment_form_wrap .btn {
  width: 100%;
  display: block;
}
.hero_banner .banner_small_title {
  font-size: 13px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
  color: #d2d2d2;
}
/* .hero_banner .banner_big_title {
  font-size: 65px;
  font-weight: 800;
  line-height: 68px;
  color: #fff;
  margin-bottom: 20px;
} */
.hero_banner .banner_description {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 40px;
}
.hero_banner .banner_btns_group {
  margin: -20px;
}
.hero_banner .banner_btns_group > li {
  padding: 20px;
}
.hero_banner :-moz-placeholder {
  color: #444;
}
.hero_banner ::-moz-placeholder {
  color: #444;
}
.hero_banner :-ms-input-placeholder {
  color: #444;
}
.hero_banner ::-webkit-input-placeholder {
  color: #444;
}
.hero_banner .tags_list {
  max-width: 400px;
}
.hero_banner.style_1 {
  margin-bottom: 59px;
}
.hero_banner.style_1 .container {
  max-width: 1430px;
}
.hero_banner.style_1 .content_wrap {
  padding: 100px;
  background-color: #20313d;
  border-radius: 8px;
}
[dir="rtl"] .hero_banner.style_1 .banner_description {
  padding-left: 190px;
  padding-right: 0px;
}
.hero_banner.style_1 .banner_description {
  padding-right: 190px;
}
.banner_image_1 {
  z-index: 1;
  position: relative;
  margin: 0 0 -130px -58px;
}
.banner_image_1 .image_wrap {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
[dir="rtl"] .banner_image_1 .satisfied_student
{
  right:-217px;
}
.banner_image_1 .satisfied_student {
  z-index: 2;
  left: -217px;
  bottom: -29px;
  padding: 40px;
  max-width: 317px;
  position: absolute;
  text-align: center;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963);
}

.banner_image_1 .satisfied_student .wrap_title {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
}
.banner_image_1 .satisfied_student .students_thumbnail > li:not(:first-child) {
  margin-left: -15px;
}
.banner_image_1 .satisfied_student .students_thumbnail span {
  width: 50px;
  height: 50px;
  display: block;
  overflow: hidden;
  border-radius: 100%;
  border: 2px solid #fff;
  background-color: #fff;
}
.banner_image_1 .deco_item {
  z-index: 1;
}
.banner_image_1 .shape_img_1 {
  top: 42%;
  left: -124px;
}
.banner_image_1 .shape_img_2 {
  top: -33px;
  right: -16px;
}
.hero_banner.style_2 {
  overflow: hidden;
  padding: 152px 0 160px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--primary-dark);
  background-position: center center;
}
.hero_banner.style_2 .banner_big_title {
  margin-right: -30px;
}
.hero_banner.style_2 .banner_big_title,
.hero_banner.style_2 .banner_description {
  color: #1f1d0d;
}
.hero_banner.style_2 .banner_description {
  max-width: 500px;
}
.banner_image_2 {
  position: relative;
}
.banner_image_2 .image_wrap_1 {
  margin-bottom: -850px;
}
.banner_image_2 .child_image {
  z-index: 1;
  position: absolute;
}
.banner_image_2 .child_image .layer {
  max-width: 260px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 10px 10px 0 0 #1f1d0d;
}
.banner_image_2 .child_image .layer:before {
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  content: "";
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url(.../public/assets/images/banner/icons_group.svg); */
}
.banner_image_2 .image_wrap_2 {
  top: 75px;
  right: -34%;
}
.banner_image_2 .image_wrap_3 {
  top: 26%;
  right: -42%;
}
.banner_image_2 .image_wrap_4 {
  top: 38%;
  left: -32%;
}
.service_item {
  border-radius: 8px;
  position: relative;
  padding: 30px 40px 40px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: #fff;
  border: 2px solid #f6f6f6;
  box-shadow: 0 1px 1px 0 rgba(31, 29, 13, 0.2);
}
.service_item:hover {
  z-index: 1;
  border-color: #1f1d0d;
  background-color: var(--primary-light);
  box-shadow: 0 20px 30px 0 rgba(31, 29, 13, 0.1);
}
.service_item:hover .item_icon {
  border-color: #1f1d0d;
}
.service_item:hover .item_icon img {
  transform: scale(0.7);
}
.service_item .item_icon {
  width: 66px;
  height: 66px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  border: 2px solid transparent;
  background-color: #fff;
}
.service_item .item_icon img {
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.service_item .item_title {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 11px;
}
.service_item p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 20px;
}
.service_item .info_list > li {
  font-size: 15px;
}
.service_item .info_list > li:not(:last-child) {
  margin-bottom: 6px;
}
.service_item .info_list i {
  font-size: 10px;
  margin: 5px 8px 0 0;
}
.course_list_wrap .course_list {
  margin: -10px;
}
.course_small_layout {
  padding: 10px;
  align-items: center;
  display: inline-flex;
  border-radius: 8px;
}
.course_small_layout .item_image {
  max-width: 120px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 8px;
}
.course_small_layout .item_image img {
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.course_small_layout .item_title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1f1d0d;
  margin-bottom: 10px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  font-family: Barlow, sans-serif;
}
.course_small_layout .item_title:hover {
  opacity: 0.8;
}
.course_small_layout .item_price {
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.course_small_layout .item_price del {
  opacity: 0.8;
  font-size: 14px;
  font-weight: 500;
  margin-right: 2px;
}
.course_small_layout .item_price b {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
}
.course_small_layout:hover {
  background-color: #f6f6f6;
}
.course_small_layout:hover .item_image img {
  transform: scale(1.08);
}
.course_small_layout:hover .item_title {
  text-decoration: underline;
}
.course_card {
  overflow: hidden;
  position: relative;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  padding: 28px 28px 32px;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #d2d2d2;
}
.course_card:hover {
  z-index: 1;
  border-color: #1f1d0d;
  transform: translate(-2px, -2px);
  box-shadow: 10px 10px 0 0 #1f1d0d;
}
.course_card .item_image {
  margin-bottom: 23px;
}
.course_card .item_image > a {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.course_card .item_image img {
  width: 100%;
  display: block;
  height: 245px;
  object-fit: cover;
}
.course_card .item_price .sale_price {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.course_card .item_price .remove_price {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.course_card .item_title {
  margin: 0;
  padding: 10px 0 24px;
}
.course_card .item_title > a {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  color: #1f1d0d;
}
.course_card .item_title > a:hover {
  opacity: 0.7;
}
.course_card.style_2 .item_image {
  margin: -28px -28px 23px;
}
.course_card.style_2 .item_image > a {
  border-radius: 0;
}
.course_card.list_layout {
  display: flex;
  align-items: center;
}
.course_card.list_layout:not(:last-child) {
  margin-bottom: 30px;
}
.course_card.list_layout .item_image {
  flex: 0 0 310px;
}
.course_card.list_layout .item_image,
.courses_archive_section .course_card.list_layout .item_image {
  margin: 0 30px 0 0;
}
.courses_archive_section .course_card .item_image {
  margin: -30px -30px 23px;
}
.filter_topbar {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
}
.filter_result {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.filter_buttons_list {
  margin: -6px;
}
.filter_buttons_list > li {
  padding: 6px;
}
.filter_buttons_list button {
  height: 44px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 19px;
  color: #1f1d0d;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: Barlow, sans-serif;
  border-radius: 8px;
  background-color: #f6f6f6;
  border: 2px solid #d2d2d2;
}
.filter_buttons_list button:hover {
  color: #fff;
  border-color: #1f1d0d;
  background-color: #1f1d0d;
}
.filter_buttons_list button i {
  margin-right: 6px;
}
.filter_buttons_list .form_item select {
  height: 44px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 30px 0 20px;
  text-transform: uppercase;
  font-family: Barlow, sans-serif;
  background-color: #f6f6f6;
}
.offCanvas_overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: saturate(180%) blur(8px);
  backdrop-filter: saturate(180%) blur(8px);
}
.offCanvas_overlay.active {
  display: block;
}
.offCanvas_close_btn {
  width: 40px;
  height: 40px;
  color: #1f1d0d;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border-radius: 8px;
  background-color: #f6f6f6;
}
.offCanvas_close_btn:hover {
  color: #fff;
  background-color: #1f1d0d;
}
.filter_offcanvas.position-fixed {
  top: 0;
  right: -320px;
  bottom: 0;
  z-index: 999;
  width: 300px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: #fff;
  box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.1);
}
.filter_offcanvas.position-fixed.active {
  right: 0;
}
.filter_offcanvas.position-fixed > .content_wrapper {
  height: 100%;
  overflow-y: scroll;
  padding: 110px 30px 50px;
}
.filter_offcanvas.position-fixed > .content_wrapper::-webkit-scrollbar {
  width: 0;
}
.filter_offcanvas.position-fixed .close_btn_wrap {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 30px;
  position: absolute;
  background-color: #fff;
}
.course_deals_section .shape_img_1 {
  top: -85px;
  left: -110px;
  max-width: 270px;
}
.course_deals_section .shape_img_2 {
  top: -46px;
  right: 158px;
  max-width: 102px;
}
.course_deals_section .shape_img_3 {
  right: -80px;
  bottom: -116px;
  max-width: 380px;
}
.popular_event_section {
  margin-bottom: 20px;
}
.popular_event_section .section_heading {
  padding-right: 100px;
}
.popular_event_section .shape_img_1 {
  top: -136px;
  left: -120px;
  max-width: 270px;
}
.popular_event_section .shape_img_2 {
  left: 44%;
  bottom: -46px;
  max-width: 270px;
}
.popular_event_section .shape_img_3 {
  top: 40%;
  right: -196px;
  max-width: 386px;
}
.popular_event_list {
  padding: 40px;
  margin-bottom: -140px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.09), 10px 10px 0 0 var(--primary-dark);
}
.popular_event_list .wrap_title {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 30px;
}
.popular_event_list ul > li {
  display: flex;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.popular_event_list ul > li:not(:last-child) {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d2d2d2;
}
.popular_event_list ul .column:nth-child(1) {
  flex: 0 0 100px;
  padding-right: 20px;
}
.popular_event_list ul .day {
  display: block;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
}
.popular_event_list ul .month {
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding: 5px 0 9px;
  text-transform: uppercase;
}
.popular_event_list ul .time {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.popular_event_list ul .event_title {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 12px;
}
.popular_event_list ul .event_name {
  color: #444;
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto, sans-serif;
}
.popular_event_list ul .event_name strong {
  font-weight: 500;
}
.popular_event_list ul .event_name small {
  font-size: 16px;
}
.event_card {
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #d2d2d2;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.event_card:hover {
  border-color: #1f1d0d;
  transform: translate(-2px, -2px);
  box-shadow: 10px 10px 0 0 #1f1d0d;
}
.event_card .item_image {
  display: block;
  overflow: hidden;
}
.event_card .item_content {
  padding: 30px;
}
.event_card .item_title {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 20px;
}
.event_card .item_title > a {
  color: #1f1d0d;
}
.event_card .item_title > a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
.event_card .meta_info_list > li {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #1f1d0d;
}
.pricing_section .shape_img_1 {
  top: 0;
  right: -32px;
  max-width: 64px;
}
.pricing_section .shape_img_2 {
  bottom: 15px;
  left: -100px;
  max-width: 173px;
}
.pricing_cards_wrapper {
  margin: -10px;
}
.pricing_cards_wrapper > .col {
  padding: 10px;
}
.pricing_card {
  position: relative;
  border-radius: 8px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  padding: 42px 40px 40px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(31, 29, 13, 0.1);
}
.pricing_card:hover {
  z-index: 1;
  box-shadow: 0 20px 40px 0 rgba(31, 29, 13, 0.08);
}
.pricing_card .card_badge {
  top: 0;
  left: 50%;
  font-size: 11px;
  padding: 0 14px;
  font-weight: 800;
  line-height: 22px;
  color: #1f1d0d;
  position: absolute;
  text-transform: uppercase;
  transform: translateX(-50%);
  border-radius: 0 0 8px 8px;
  background-color: var(--primary-dark);
}
.pricing_card .card_heading {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 21px;
  text-transform: uppercase;
}
.pricing_card .pricing_wrap {
  color: #0e081e;
  font-weight: 800;
  font-family: Barlow, sans-serif;
}
.pricing_card .pricing_wrap sub,
.pricing_card .pricing_wrap sup {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
  display: inline-block;
  vertical-align: middle;
}
.pricing_card .pricing_wrap sup {
  top: -20px;
}
.pricing_card .pricing_wrap .price_value {
  font-size: 65px;
  line-height: 65px;
  display: inline-block;
}
.pricing_card .pricing_wrap small {
  color: #a09f9f;
  font-size: 14px;
  padding-top: 5px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, sans-serif;
}
.pricing_card hr {
  margin: 30px 0;
}
.pricing_card .info_list {
  margin: auto;
  display: inline-block;
}
.pricing_card .info_list.unordered_list_block > li:not(:last-child) {
  margin-bottom: 10px;
}
.pricing_card .info_list i {
  font-size: 18px;
  text-shadow: none;
  color: #1f1d0d;
  margin: 3px 10px 0 0;
}
.pricing_card .btn_wrap {
  padding: 38px 0;
}
.pricing_card .btn {
  width: 100%;
  display: block;
}
.pricing_card.bg_dark {
  color: #fff;
  padding: 62px 40px 60px;
  background-color: #1f1d0d;
}
.pricing_card.bg_dark .card_heading,
.pricing_card.bg_dark .info_list i,
.pricing_card.bg_dark .pricing_wrap .price_value {
  color: #fff;
}
.pricing_card.bg_dark .pricing_wrap small {
  color: #c8c8c8;
}
.advertisement_section {
  margin: 30px 0;
}
.advertisement_section .section_heading {
  padding-right: 70px;
}
.advertisement_section .images_group .col:nth-child(1) {
  margin-bottom: -45px;
}
.advertisement_section .images_group .col:nth-child(2) {
  margin-top: -45px;
}
.advertisement_section .images_group .image_wrap {
  overflow: hidden;
  border-radius: 8px;
}
.advertisement_section .images_group .image_wrap:not(:last-child) {
  margin-bottom: 30px;
}
.advertisement_section .images_group .shape_img_1 {
  left: -106px;
  bottom: 83px;
  max-width: 270px;
}
.advertisement_section .images_group .shape_img_2 {
  top: 73px;
  right: -138px;
  max-width: 102px;
}
.testimonial_carousel {
  margin: -15px;
}
.testimonial_carousel .carousel_item {
  padding: 15px;
  padding-bottom: 0px;
}
.testimonial_item {
  display: flex;
  padding: 30px;
  position: relative;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d2d2d2;
}
.testimonial_item .testimonial_image {
  margin: auto;
  flex: 0 0 170px;
  max-width: 170px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 8px;
}
.testimonial_item .rating_star {
  margin-bottom: 10px;
}
.testimonial_item .testimonial_title {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 10px;
}
.testimonial_item p {
  color: #29281e;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px;
}
.testimonial_item .testimonial_designation {
  color: #29281e;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 5px;
  font-family: Roboto, sans-serif;
}
.testimonial_item .testimonial_name {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 16px;
  text-transform: uppercase;
}
.testimonial_item .quote_icon {
  top: 30px;
  right: 30px;
  line-height: 1;
  font-size: 34px;
  position: absolute;
  text-shadow: 2px -2px 0 var(--primary-light);
}
.testimonial_item_2 {
  display: flex;
  align-items: center;
}
.testimonial_item_2 .testimonial_image {
  margin: 0 80px;
  flex: 0 0 520px;
  overflow: hidden;
  display: inline-flex;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  background-color: var(--primary-dark);
  background-position: center center;
}
.testimonial_item_2 .testimonial_image.image_widget {
  box-shadow: 15px 15px 0 0 var(--primary-dark);
}
.testimonial_item_2 .quote_icon {
  margin-bottom: 40px;
}
.testimonial_item_2 .testimonial_title {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: #1f1d0d;
  margin-bottom: 15px;
  font-family: Barlow, sans-serif;
}
.testimonial_item_2 p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #29281e;
  margin-bottom: 30px;
  font-family: Roboto, sans-serif;
}
.testimonial_item_2 .testimonial_admin {
  display: flex;
  align-items: center;
}
.testimonial_item_2 .admin_image {
  flex: 0 0 60px;
  overflow: hidden;
  margin-right: 15px;
  border-radius: 8px;
}
.testimonial_item_2 .testimonial_name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 5px;
  font-family: Roboto, sans-serif;
}
.testimonial_item_2 .testimonial_designation {
  display: block;
  font-size: 14px;
  line-height: 20px;
}
.newslatter_section {
  background-image: linear-gradient(0deg, #1f1d0d 120px, transparent 0);
}

.blog_small_group {
  margin: -10px;
}
.blog_small_group > li:not(:last-child) {
  margin-bottom: 2px;
}
.blog_small {
  padding: 10px;
  display: flex;
  max-width: 350px;
  align-items: center;
  border-radius: 8px;
}
.blog_small:hover {
  background-color: #f6f6f6;
}
.blog_small .item_image {
  flex: 0 0 120px;
  max-width: 120px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 8px;
}
.blog_small .item_image img {
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.blog_small .item_author {
  line-height: 1;
  display: block;
  color: #29281e;
  margin-bottom: 8px;
}
.blog_small .item_title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #1f1d0d;
  margin-bottom: 8px;
  font-family: Barlow, sans-serif;
}
.blog_small .item_post_date {
  line-height: 1;
  color: #c8c8c8;
  display: block;
  font-size: 14px;
}
.blog_small:hover {
  background-color: #f6f6f6;
}
.blog_small:hover .item_image img {
  transform: scale(1.08);
}
.blog_small:hover .item_title {
  text-decoration: underline;
}
.blog_item {
  position: relative;
  padding-bottom: 10px;
}
.blog_item .item_image {
  margin-bottom: 15px;
}
.blog_item .item_image > a {
  display: block;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}
.blog_item .item_image > a img {
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.blog_item .item_image > a:hover img {
  transform: scale(1.08);
}
.blog_item .item_category_list {
  margin-bottom: 14px;
}
.blog_item:has(.item_image) .item_category_list {
  margin: 0;
  top: 20px;
  right: 20px;
  z-index: 2;
  position: absolute;
}
.blog_item .item_title {
  margin-bottom: 18px;
}
.blog_item .item_title a {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
  color: #1f1d0d;
}
.blog_item .item_title a:hover {
  opacity: 0.7;
}
.blog_item .meta_info_list {
  margin-bottom: 10px;
}
.blog_item .meta_info_list i {
  color: #1f1d0d;
}
.calltoaction_section {
  margin-bottom: 30px;
}
.calltoaction_section .section_heading .heading_description {
  max-width: 456px;
}
.calltoaction_section .shape_img_1 {
  left: 44%;
  bottom: -26px;
  max-width: 169px;
}
.calltoaction_section .shape_img_2 {
  top: -60px;
  right: 162px;
  max-width: 169px;
}
.calltoaction_section .shape_img_3 {
  top: 85px;
  left: -74px;
  max-width: 148px;
}
.calltoaction_section .shape_img_4 {
  left: 44%;
  bottom: -100px;
  max-width: 373px;
}
.calltoaction_section .shape_img_5 {
  top: -56px;
  right: -40px;
  max-width: 148px;
}
.calltoaction_section .info_list {
  margin-bottom: 40px;
}
.calltoaction_section .info_list i {
  font-size: 14px;
  margin: 6px 10px 0 0;
}
.calltoaction_section .boxed_wrapper {
  margin: auto;
  max-width: 1400px;
  padding: 100px 115px;
  background-color: #1f1d0d;
  border-radius: 8px;
}
.calltoaction_form {
  z-index: 1;
  padding: 40px;
  position: relative;
  margin-bottom: -150px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 10px 10px 0 0 var(--primary-dark);
}
.calltoaction_form .form_title {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 30px;
}
.calltoaction_form .form_item input,
.calltoaction_form .form_item textarea {
  border-width: 1px;
  background-color: #f6f6f6;
}
.calltoaction_form .form_item input:focus,
.calltoaction_form .form_item textarea:focus {
  background-color: #fff;
}
.calltoaction_form .form_item textarea {
  min-height: 127px;
}
.brands_logo_list {
  margin: -15px;
  justify-content: center;
}
.brands_logo_list > li {
  width: 25%;
  padding: 15px;
}
.brands_logo_list a {
  padding: 30px;
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #f6f6f6;
}

.brands_logo_list a:hover {
  border-color: #1f1d0d;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 0 #1f1d0d;
}
.brands_logo_list.style_2 li {
  width: 16.666%;
}
.brands_logo_list.style_2 a {
  padding: 0;
  height: auto;
  border: none;
  background-color: transparent;
}
.brands_logo_list.style_2 a:hover {
  box-shadow: none;
  transform: unset;
}
.brands_logo_list.style_2 a img {
  max-height: 50px;
  max-width: 166px;
}
.mentor_item {
  padding: 30px;
  text-align: center;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d2d2d2;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.mentor_item .mentor_image {
  margin-bottom: 20px;
}
.mentor_item .mentor_image > a {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.mentor_item .mentor_image > a:before {
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  width: 100px;
  height: 100px;
  line-height: 1;
  font-size: 24px;
  content: "";
  font-weight: 400;
  text-align: right;
  position: absolute;
  color: var(--primary-light);
  padding: 20px 18px 0 0;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: #1f1d0d;
  font-family: "Font Awesome 5 Pro";
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}
.mentor_item .mentor_name {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 10px;
}
.mentor_item .mentor_name > a {
  color: #1f1d0d;
}
.mentor_item .mentor_name > a:hover {
  opacity: 0.7;
}
.mentor_item .mentor_designation {
  margin-bottom: 15px;
}
.mentor_item:hover {
  z-index: 1;
  border-color: #1f1d0d;
  transform: translate(-2px, -2px);
  box-shadow: 10px 10px 0 0 #1f1d0d;
}
.mentor_item:hover .mentor_image > a:before {
  opacity: 1;
}
.mentor_item:hover .mentor_name > a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
.site_header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 29px 0;
  position: absolute;
  transition: padding 0.3s;
}
.site_header .container {
  max-width: 1430px;
}
.site_header.sticky {
  padding: 10px 0;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.96);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  box-shadow: 0 2px 6px 0 rgba(16, 18, 19, 0.08);
  transition: background-color 0.3s ease-in, padding 0.3s;
}
/* .site_link {
  display: flex;
} */
.site_logo,
.site_logo a,
.site_logo a:hover {
  font-family: Barlow, sans-serif;
  font-weight: 500;
  /* font-size: 32px; */
  color: #1f1d0d;
  line-height: 20px;
  font-size: 13px;
}
.site_logo img {
  height: 42px;
  max-height: 52px;
  margin-right: 10px;
}
.main_menu {
  padding: 0;
}
.main_menu_list > li {
  margin: 0 1px;
  line-height: 1;
  font-size: 16px;
}
.main_menu_list > li > a {
  z-index: 1;
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  color: #1f1d0d;
  position: relative;
  align-items: center;
  display: inline-flex;
  padding: 9px 17px 8px;
  font-family: Roboto, sans-serif;
  border-radius: 8px;
}
.main_menu_list > li.active > a,
.main_menu_list > li:hover > a {
  color: #1f1d0d;
  background-color: #f6f6f6;
}
.main_menu_list > li:hover > .dropdown-menu{
  display: block;
}
.main_menu_list > li.dropdown > .dropdown-menu .dropdown:hover > .dropdown-menu{
  display: block;
}
.main_menu_list > li:hover > a:after {
  transform: rotateX(-180deg);
}
.main_menu_list .dropdown > a {
  position: relative;
}

.main_menu_list .dropdown-menu {
  top: 100%;
  display: none;
  margin: 2px 0 0;
  padding: 14px 0;
  animation: unset;
  min-width: 210px;
  inset: 100% auto auto auto;
  border-radius: 8px;
  border: 2px solid #1f1d0d;
  box-shadow: -6px 6px 0 0 #101213;
}
.main_menu_list .dropdown-menu:before {
  top: -4px;
  height: 2px;
}
.main_menu_list .dropdown-menu > li:not(:last-child) {
  margin-bottom: 1px;
}
.main_menu_list .dropdown-menu > li > a {
  display: block;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  color: #29281e;
  position: relative;
  white-space: nowrap;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  padding: 14px 30px 12px 32px;
}
.main_menu_list .dropdown-menu > li > a:before {
  top: 19px;
  left: 20px;
  width: 4px;
  height: 4px;
  content: "";
  opacity: 0.7;
  position: absolute;
  border-radius: 100%;
  background-color: #1f1d0d;
}
.main_menu_list .dropdown-menu > li.active > a,
.main_menu_list .dropdown-menu > li:hover > a {
  background-color: var(--primary-light);
}
.main_menu_list .dropdown-menu .dropdown > a:after {
  margin-top: 1px;
  content: "";
}
.main_menu_list .dropdown-menu .dropdown-menu {
  top: 0;
  margin: 0;
  left: 100%;
}
.main_menu_list .dropdown-menu:hover > .dropdown-menu{
  display:block;
}
.main_menu_list .dropdown-menu.show {
  display: block;
}
.main_menu_list .dropdown-menu.show > li {
  animation: 0.2s dropDown;
}
.header_btns_group > li {
  margin-left: 14px;
}
.header_btns_group > li:first-child {
  display: none;
}
.header_btns_group .btn {
  padding: 0 26px;
}
.header_btns_group .btn span small {
  padding: 6px 0;
}
.site_header_1 + main {
  padding-top: 130px;
}
.site_header_2 .container {
  max-width: 1200px;
}

.site_footer .site_logo {
  margin-bottom: 35px;
}
.site_footer .footer_widget_area {
  padding: 130px 0 108px;
}
.site_footer p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 52px;
}
.site_footer .footer_widget_title {
  color: #fff;
}
.site_footer .page_list a {
  color: #fff;
}
.site_footer .page_list a:hover {
  color: var(--primary-light);
}
.site_footer .blog_small:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.site_footer .blog_small .item_image {
  flex: 0 0 70px;
  max-width: 70px;
  margin-right: 10px;
}
.site_footer .blog_small .item_author {
  display: none;
}
.site_footer .blog_small .item_title {
  font-size: 16px;
  color: #fff;
}
.footer_widget_title {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 35px;
}
.copyright_widget .copyright_text {
  padding: 40px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-top: 1px solid #444;
}
.copyright_widget .copyright_text a {
  color: #c8c8c8;
}
.copyright_widget .copyright_text a:hover {
  color: #fff;
}

.page_banner .container {
  max-width: 1430px;
}
.page_banner .info_list,
.page_banner .meta_info_list {
  padding: 30px 0 40px;
}
.page_banner .btns_group {
  margin: -15px;
  display: flex;
  align-items: center;
}
.page_banner .btns_group > li {
  padding: 15px;
}
.page_banner .item_price {
  line-height: 1;
  font-family: Barlow, sans-serif;
}
.page_banner .item_price .remove_price {
  font-size: 24px;
  font-weight: 500;
  margin-right: 4px;
  color: #29281e;
}
.page_banner .item_price .sale_price {
  font-size: 45px;
  font-weight: 700;
  color: #1f1d0d;
}
.page_title {
  font-size: 65px;
  font-weight: 800;
  line-height: 68px;
  margin-bottom: 10px;
}

.page_banner_image {
  margin-bottom: -120px;
  box-shadow: 20px 20px 0 0 var(--primary-dark);
}
.breadcrumb_nav {
  margin-bottom: 30px;
}

.breadcrumb_nav > li:not(:last-child) {
  margin-right: 12px;
  padding-right: 12px;
}
.breadcrumb_nav > li:not(:last-child):after {
  top: 1px;
  right: -5px;
  opacity: 0.7;
  font-weight: 400;
  content: "";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
}

.breadcrumb_nav > li > a:hover {
  opacity: 1;
}
.sidebar .widget_title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 25px;
  color: #1f1d0d;
  position: relative;
  padding-right: 20px;
  font-family: Barlow, sans-serif;
}
.sidebar .widget_title:before {
  top: 2px;
  right: 0;
  content: "";
  font-weight: 500;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
}
.sidebar .widget_title[aria-expanded="false"]:before {
  content: "";
}
.sidebar .widget {
  padding-bottom: 25px;
  border-bottom: 1px solid #d2d2d2;
}
.sidebar .widget:not(:last-child) {
  margin-bottom: 25px;
}
.sidebar .widget .card {
  border: none;
  border-radius: 0;
  padding: 20px 0 0;
  background-color: transparent;
}
.sidebar .checkbox_item {
  margin-bottom: 10px;
}
.sidebar .checkbox_item input {
  top: 3px;
}
.sidebar .checkbox_item label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar .ratings_widget .checkbox_item i {
  color: var(--primary-dark);
}
.course_details_section .sidebar .widget {
  border: none;
  padding: 0;
}
.course_details_section .sidebar .widget:not(:last-child) {
  margin-bottom: 60px;
}
.course_details_section .sidebar .widget_title {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
}
.course_details_section .sidebar .widget_title:before {
  display: none;
}
.callbox_wrap {
  padding: 30px;
  margin-bottom: 60px;
  border-radius: 8px;
  border: 1px solid #1f1d0d;
  background-color: #fff;
}
.callbox_wrap .btn:not(:last-child) {
  margin-bottom: 20px;
}
.details_section .details_image.image_widget {
  box-shadow: -20px -20px 0 0 #1f1d0d;
}
.details_section .details_content .details_item_title {
  font-size: 45px;
  line-height: 50px;
  margin-bottom: 26px;
}
.details_section .details_content .mentor_name {
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 10px;
}
.details_section .details_content .details_info_title {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 20px;
}
.details_section .details_content .mentor_designation {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
}
.details_section .details_content .meta_info_list {
  margin-bottom: 30px;
}
.details_section .details_content p {
  margin-bottom: 30px;
}
.details_section .details_content hr {
  margin: 40px 0;
}
.course_info_card {
  padding: 30px;
  margin-bottom: 60px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 11px 11px 0 0 #1f1d0d;
}
.course_info_card .details_image {
  overflow: hidden;
  border-radius: 8px;
}
.course_info_card .details_image,
.course_info_card .item_price,
.course_info_card .meta_info_list {
  margin-bottom: 20px;
}
.course_info_card .item_price .sale_price {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  font-family: Barlow, sans-serif;
}
.course_info_card .btn {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}
.course_info_card .course_info_list {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.course_info_card .course_info_list > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.course_info_card .course_info_list > li:not(:last-child) {
  margin-bottom: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #d2d2d2;
}
.course_info_card .course_info_list i {
  width: 20px;
  margin-right: 5px;
  color: var(--primary-dark);
}
.course_details_section .sidebar .course_info_card,
.event_details_section .sidebar .course_info_card {
  z-index: 1;
  margin-top: -420px;
  position: relative;
}
.blog_details_section .details_image {
  margin-bottom: 20px;
}
.blog_details_section .details_content .meta_info_list {
  margin-bottom: 30px;
}
.blog_details_section .details_content .meta_info_list i {
  color: #1f1d0d;
}
.blog_details_section .comments_list_wrap {
  margin: 80px 0;
}
.blog_author {
  display: flex;
  padding: 30px;
  margin: 80px 0;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d2d2d2;
}
.blog_author .blog_author_image {
  flex: 0 0 140px;
  overflow: hidden;
  margin-right: 30px;
  border-radius: 8px;
}
.blog_author .author_name {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 10px;
}
.blog_author .author_designation {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #29281e;
  margin-bottom: 15px;
  font-family: Roboto, sans-serif;
}
.blog_author .blog_author_link {
  top: 0;
  right: 0;
  padding: 0 32px;
  position: absolute;
}
.blog_author .blog_author_link span small {
  padding: 8px 0;
}
.prevnext_post_wrap {
  display: flex;
  justify-content: space-between;
}
.prevnext_post_wrap .post_item {
  max-width: 300px;
}
.prevnext_post_wrap .post_item span {
  margin-bottom: 15px;
  align-items: center;
  display: inline-flex;
}
.prevnext_post_wrap .post_item span i {
  width: 20px;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  border-radius: 100%;
  margin: -1px 5px 0 0;
  color: var(--primary-light);
  background-color: #1f1d0d;
}
.prevnext_post_wrap .post_item span small {
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  color: #29281e;
  text-transform: uppercase;
}
.prevnext_post_wrap .post_item strong {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.prevnext_post_wrap .post_item strong:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.prevnext_post_wrap .post_item:last-child {
  text-align: right;
}
.prevnext_post_wrap .post_item:last-child span i {
  order: 1;
  margin: -1px 0 0 5px;
}
.event_speakers .mentor_item {
  padding: 10px 10px 18px;
}
.event_speakers .mentor_item .mentor_image {
  margin-bottom: 12px;
}
.event_speakers .mentor_item .mentor_name {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 6px;
}
.event_speakers .mentor_item .mentor_designation {
  font-size: 16px;
  font-weight: 400;
}
.contact_info_iconbox {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
a .contact_info_iconbox:not(:last-child) {
  margin-bottom: 30px;
}
/* .contact_info_iconbox .item_icon {
  width: 62px;
  height: 62px;
  flex: 0 0 62px;
  color: #1f1d0d;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-dark);
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 4px 4px 0 0 #1f1d0d;
} */
.contact_info_iconbox .item_title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
}
#gmap_canvas_iframe {
  width: 100%;
  height: 524px;
  border-radius: 8px;
  border: 3px solid var(--primary-dark);
}
.contact_form_section .shape_img_1 {
  right: 0;
  top: 150px;
  max-width: 102px;
}
.contact_form_section .shape_img_2 {
  left: 0;
  bottom: 130px;
  max-width: 72px;
}
.contact_form_section .shape_img_3 {
  top: -204px;
  left: -150px;
  max-width: 446px;
}
.contact_form_section .shape_img_4 {
  right: -270px;
  bottom: -160px;
  max-width: 643px;
}
.error_section {
  display: flex;
  padding: 55px 0;
  min-height: 100vh;
  align-items: center;
}
.error_section .error_image {
  max-width: 670px;
  margin: auto auto 20px;
}
.error_section .error_title {
  font-size: 60px;
  line-height: 66px;
  margin-bottom: 20px;
}
.error_section p {
  font-size: 16px;
  max-width: 500px;
  line-height: 24px;
  margin: auto auto 40px;
}
.error_section .shape_img_1 {
  right: 0;
  top: 150px;
  max-width: 102px;
}
.error_section .shape_img_2 {
  left: 0;
  bottom: 130px;
  max-width: 72px;
}
.error_section .shape_img_3 {
  top: -204px;
  left: -150px;
  max-width: 446px;
}
.error_section .shape_img_4 {
  right: -270px;
  bottom: -160px;
  max-width: 643px;
}
.register_heading {
  line-height: 1;
  font-size: 54px;
  margin: -60px 0 18px;
}
.register_heading_description {
  font-size: 18px;
  margin-bottom: 40px;
}
.register_form {
  z-index: 1;
  padding: 60px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #1f1d0d;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.12), 10px 10px 0 0 #1f1d0d;
}
.register_form .form_item input,
.register_form .form_item textarea {
  border-color: #1f1d0d;
}
.register_form .icon_list > li:not(:last-child) {
  margin-bottom: 10px;
}
.register_form .icon_list i {
  float: left;
  font-size: 16px;
  font-style: normal;
  color: #1f1d0d;
  margin-right: 10px;
}
.register_form .icon_list span {
  display: table;
}
.register_form .note_text {
  font-size: 18px;
  color: #1f1d0d;
  font-style: italic;
}
.register_form hr {
  margin: 30px 0;
}
.register_form.signup_login_form .form_item {
  margin-bottom: 20px;
}
.register_form.signup_login_form .btn {
  width: 100%;
  display: block;
}
@media screen and (max-width: 1440px) {
  .intro_video_section .shape_img_3 {
    right: -160px;
    bottom: -180px;
    max-width: 360px;
  }
}
@media screen and (max-width: 1360px) {
  .page_banner .content_wrapper {
    padding: 70px;
  }
}
@media screen and (max-width: 1199px) {
  .hero_banner.style_1 .content_wrap {
    padding: 80px 40px;
  }
  .hero_banner .banner_big_title {
    font-size: 54px;
    line-height: 58px;
  }
  .banner_image_1 .satisfied_student {
    left: -150px;
    padding: 30px;
  }
  .banner_image_1 .satisfied_student .students_thumbnail span {
    width: 40px;
    height: 40px;
  }
  .banner_image_1 .satisfied_student .wrap_title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .form_item:has(.btn) input {
    flex: 0 0 340px;
  }
  .image_widget {
    box-shadow: -5px 5px 0 0 var(--primary-dark);
  }
  
  .calltoaction_form {
    box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 5px 5px 0 0 var(--primary-dark);
  }
  .details_section .details_image.image_widget {
    box-shadow: -5px -5px 0 0 #1f1d0d;
  }
  .course_info_card {
    box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 5px 5px 0 0 #1f1d0d;
  }
  .testimonial_item_2 .testimonial_image.image_widget {
    box-shadow: 5px 5px 0 0 var(--primary-dark);
  }
  .popular_event_list {
    box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.09), 5px 5px 0 0 var(--primary-dark);
  }
  .details_section .details_image.image_widget {
    box-shadow: -5px -5px 0 0 #1f1d0d;
  }
  .register_form {
    box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.12), 5px 5px 0 0 #1f1d0d;
  }
  .main_menu_list > li > a {
    padding: 9px 16px 8px;
  }
  .header_btns_group .btn {
    padding: 0 20px;
  }
  .advertisement_section {
    margin: 0;
    padding: 70px 0;
  }
  .advertisement_section .section_heading {
    padding-right: 0;
  }
  .advertisement_section .images_group .shape_img_1 {
    left: -60px;
    bottom: 83px;
    max-width: 180px;
  }
}
@media screen and (max-width: 1024px) {
  .main_menu_list > li > a {
    font-size: 16px;
    line-height: 22px;
    padding: 7px 14px 6px;
  }
  .header_btns_group .btn {
    padding: 0 18px;
  }
  .header_btns_group > li {
    margin-left: 8px;
  }
  .banner_image_1 {
    margin: 0;
  }
  .banner_image_1 .satisfied_student {
    left: 30px;
    padding: 20px;
  }
  .banner_image_1 .shape_img_1 {
    left: -50px;
  }
  .banner_image_1 .shape_img_2 {
    right: -33px;
  }
  .service_item {
    padding: 30px 30px 35px;
  }
  .counter_item .counter_value {
    font-size: 48px;
    line-height: 54px;
  }
  .section_heading .heading_text {
    font-size: 38px;
    line-height: 42px;
  }
  .advertisement_section .images_group .shape_img_1 {
    left: -78px;
    max-width: 190px;
  }
  .testimonial_item .quote_icon {
    top: 15px;
    right: 15px;
    font-size: 28px;
  }
  .form_item:has(.btn) input {
    margin-right: 5px;
  }
  .hero_banner.style_2 .form_item:has(.btn) input {
    flex: 0 0 330px;
  }
  .testimonial_item_2 .testimonial_title {
    font-size: 24px;
  }
  .page_banner .form_item:has(.btn) {
    display: block;
  }
  .page_banner .form_item .btn {
    width: 100%;
    display: block;
    margin-top: 10px;
  }
  .page_banner .content_wrapper {
    background-size: 470px;
  }
  .brands_logo_list.style_2 li {
    width: auto;
  }
  .page_title {
    font-size: 48px;
    line-height: 54px;
  }
  .page_banner .info_list,
  .page_banner .meta_info_list {
    padding: 20px 0 30px;
  }
  .page_banner_image {
    margin: auto;
  }
  .error_section .error_image {
    max-width: 470px;
  }
  .error_section .error_title {
    font-size: 48px;
    line-height: 54px;
  }
  .error_section .shape_img_3 {
    top: -120px;
    left: -130px;
    max-width: 300px;
  }
  .error_section .shape_img_4 {
    right: -250px;
    max-width: 500px;
  }
  .error_section .shape_img_1 {
    top: 80px;
    right: 15px;
    max-width: 80px;
  }
  .error_section .shape_img_2 {
    left: 15px;
    bottom: 100px;
    max-width: 70px;
  }
  .register_form {
    padding: 40px;
  }
}
@media screen and (max-width: 991px) {
  .header_btns_group > li:first-child {
    display: inline-block;
  }
  .mobile_menu_btn {
    font-size: 24px;
    margin-right: 10px;
    transform: translateY(2px);
  }
  .container,
  .hero_banner.style_1 .container,
  .site_header .container {
    max-width: 730px;
  }
  .main_menu {
    left: 0;
    right: 0;
    top: 129px;
    z-index: 999;
    position: fixed;
  }
  .site_header.sticky {
    background-color: #fff;
  }
  .site_header.sticky .main_menu {
    top: 61px;
  }
  .main_menu_inner {
    background-color: #fff;
    border-bottom: 1px solid #d2d2d2;
  }
  .main_menu_list > li {
    width: 100%;
    margin: 1px 0;
    display: block;
  }
  .main_menu_list {
    margin: auto;
    padding: 15px;
    max-width: 730px;
  }
  .main_menu_list > li > a {
    width: 100%;
    display: block;
  }
  .main_menu_list .dropdown-menu {
    position: static;
    box-shadow: none;
  }
  .main_menu_list > li > a {
    font-size: 18px;
    line-height: 24px;
    padding: 12px 20px;
  }
  .main_menu_list .dropdown > a:after {
    font-size: 16px;
    margin-top: 5px;
  }
  .main_menu_list .dropdown-menu {
    padding: 8px 0;
  }
  .hero_banner {
    text-align: center;
  }
  .hero_banner.style_1 .banner_description {
    padding-right: 0;
  }
  .hero_banner .banner_btns_group {
    justify-content: center;
  }
  .banner_image_1 {
    margin: 20px 0 0;
  }
  .hero_banner.style_1 {
    margin-bottom: 20px;
  }
  .section_space_lg {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .courses_info_section .image_widget,
  .expect_from_course .image_widget {
    margin-bottom: 30px;
  }
  .advertisement_section {
    margin: 0;
    padding: 90px 0;
  }
  .testimonial_section .section_heading {
    text-align: center;
  }
  .hero_banner.style_2 .banner_description {
    margin: auto auto 30px;
  }
  .hero_banner.style_2 .form_item {
    justify-content: center;
  }
  .hero_banner .tags_list {
    margin: auto;
    justify-content: center;
  }
  .hero_banner .banner_image_2 {
    max-width: 500px;
    margin: 20px auto auto;
  }
  .banner_image_2 .image_wrap_1 {
    margin-bottom: -780px;
  }
  .banner_image_2 .child_image .layer {
    max-width: 180px;
  }
  .banner_image_2 .image_wrap_2 {
    right: -15%;
  }
  .banner_image_2 .image_wrap_3 {
    top: 24%;
    right: -30%;
  }
  .banner_image_2 .image_wrap_4 {
    top: 30%;
    left: -24%;
  }
  .testimonial_item_2 {
    display: block;
  }
  .testimonial_item_2 .testimonial_image {
    margin: auto auto 40px;
  }
  .testimonial_item_2 .quote_icon {
    margin-bottom: 20px;
  }
  .calltoaction_form {
    margin: auto;
  }
  .calltoaction_section .shape_img_2 {
    top: -44px;
    right: 50px;
    max-width: 120px;
  }
  .calltoaction_section .shape_img_1 {
    left: 50px;
    bottom: -10px;
    max-width: 120px;
  }
  .brands_logo_list > li {
    width: 33.333%;
  }
  .brands_logo_list a img {
    max-width: 198px;
    max-height: 114px;
  }
  .form_item:has(.btn) {
    margin-bottom: 30px;
  }
  .hero_banner.style_2 .banner_big_title {
    margin: 0 0 20px;
  }
  .page_banner .content_wrapper {
    padding: 70px;
    text-align: center;
    background-size: 300px;
  }
  .breadcrumb_nav {
    justify-content: center;
  }
  .page_description {
    padding: 0;
  }
  .popular_event_list {
    margin: auto;
  }
  .popular_event_section .shape_img_3 {
    right: -90px;
    max-width: 180px;
  }
  .popular_event_section .shape_img_1 {
    left: -90px;
    max-width: 180px;
  }
  .popular_event_section .shape_img_2 {
    bottom: 20px;
    max-width: 120px;
  }
  .sidebar {
    margin-top: 40px;
  }
  .contact_form_section .shape_img_4 {
    right: -200px;
    bottom: -100px;
    max-width: 470px;
  }
  .contact_form_section .shape_img_3 {
    top: -120px;
    left: -120px;
    max-width: 300px;
  }
  .page_banner .info_list,
  .page_banner .meta_info_list {
    justify-content: center;
  }
  .page_banner .btns_group {
    justify-content: center;
  }
  .service_item {
    padding: 25px 20px 30px;
  }
  .site_footer p {
    margin-bottom: 25px;
  }
  .footer_widget_title {
    line-height: 1;
    margin-bottom: 20px;
  }
  .site_footer .site_logo {
    margin-bottom: 25px;
  }
  .footer_widget {
    margin-bottom: 10px;
  }
  .site_footer .footer_widget_area {
    padding: 100px 0 80px;
  }
  
  .intro_video_section .shape_img_2 {
    left: -58px;
    max-width: 140px;
  }
  .intro_video_section .shape_img_3 {
    right: -110px;
    bottom: -120px;
    max-width: 230px;
  }
  .page_banner .content_wrapper {
    text-align: left;
  }
  .page_banner .unordered_list {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 767px) {
  .header_btns_group {
    margin-left: -100px;
  }
  .header_btns_group .btn span small {
    font-size: 16px;
  }
  .advertisement_section .section_heading {
    padding: 0;
  }
  .popular_event_section .section_heading {
    padding: 0;
  }
  .btn_wrap {
    padding: 30px 0;
  }
  .section_heading {
    margin-bottom: 30px;
  }
  .intro_video_section .shape_img_3 {
    right: -100px;
    max-width: 180px;
  }
  .breadcrumb_nav > li {
    font-size: 14px;
    line-height: 18px;
  }
}
@media screen and (max-width: 680px) {
  .course_card.list_layout {
    display: block;
  }
  .course_card.list_layout .item_image,
  .courses_archive_section .course_card.list_layout .item_image {
    margin: 0 0 30px 0;
  }
  .calltoaction_section .boxed_wrapper {
    padding: 100px 15px;
  }
  .faq_section .section_heading.text-center,
  .newslatter_box .section_heading.text-center,
  .policy_section .section_heading.text-center,
  .process_section .section_heading.text-center {
    text-align: left !important;
  }
}
@media screen and (max-width: 575px) {
  .advertisement_section .images_group .col:nth-child(1),
  .advertisement_section .images_group .col:nth-child(2) {
    margin: 0;
  }
  .header_btns_group .btn span small {
    font-size: 14px;
    line-height: 18px;
  }
  .header_btns_group .btn {
    padding: 0 12px;
  }
  .header_btns_group .btn span small {
    padding: 4px 0;
  }
  .mobile_menu_btn {
    margin-right: 8px;
    color: #1f1d0d;
  }
  .mobile_menu_btn:hover {
    color: #1f1d0d;
  }
  
  .hero_banner .banner_image_2 {
    max-width: 400px;
  }
  .banner_image_2 .child_image .layer {
    max-width: 130px;
  }
  .banner_image_2 .image_wrap_1 {
    margin-bottom: -680px;
  }
  .banner_image_2 .image_wrap_4 {
    left: -10%;
  }
  .banner_image_2 .image_wrap_3 {
    right: -18%;
  }
  .banner_image_2 .image_wrap_2 {
    right: -10%;
  }
  .brands_logo_list > li {
    width: 50%;
  }
  .hero_banner.style_2 .form_item {
    display: block;
  }
  .hero_banner.style_2 .form_item .btn {
    width: 100%;
    display: block;
    margin-top: 10px;
  }
  .testimonial_item_2 .testimonial_image.image_widget {
    box-shadow: 10px 10px 0 0 var(--primary-dark);
  }
  .popular_event_list ul > li {
    display: block;
  }
  .popular_event_list ul .column:nth-child(1) {
    padding: 0 0 20px;
  }
  blockquote {
    display: block;
  }
  blockquote .blockquote_icon {
    margin: 0 0 10px;
  }
  .blog_author {
    margin: 50px 0;
    display: block;
  }
  .blog_author .blog_author_image {
    margin: 0 0 20px;
  }
  .blog_author .blog_author_link {
    position: static;
    margin: 20px 0 0;
  }
  .prevnext_post_wrap {
    display: block;
  }
  .prevnext_post_wrap .post_item {
    padding: 30px;
    display: block;
    max-width: 100%;
    border-radius: 6px;
    border: 2px solid #d2d2d2;
  }
  .prevnext_post_wrap .post_item:not(:last-child) {
    margin-bottom: 6px;
  }
  .filter_topbar {
    display: block;
  }
  .filter_result {
    margin: 0 0 24px;
  }
  .deals_countdown {
    display: block;
  }
  .deals_countdown .countdown_timer {
    width: 100%;
  }
  .deals_countdown .discount_value {
    width: 100%;
    margin: 15px 0 0;
  }
  .error_section .error_image {
    max-width: 370px;
  }
  .error_section p {
    font-size: 14px;
    line-height: 24px;
    margin: auto auto 30px;
  }
  .error_section .form_item:has(.btn) {
    display: block;
  }
  .error_section .form_item .btn {
    width: 100%;
    display: block;
    margin: 10px 0 0;
  }
  .details_section .details_content .mentor_name {
    font-size: 36px;
    line-height: 42px;
  }
  .details_section .details_content .details_item_title {
    line-height: 1;
    font-size: 40px;
    margin-bottom: 18px;
  }
  .accordion.style_2 .accordion-button {
    font-size: 18px;
    line-height: 22px;
  }
  .video_play_btn .icon {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    font-size: 14px;
  }
}
@media screen and (max-width: 425px) {
  .mobile_menu_btn {
    margin-right: 6px;
    font-size: 20px;
  }
  .header_btns_group > li {
    margin-left: 4px;
  }
  .header_btns_group .btn span small {
    font-size: 12px;
    line-height: 14px;
  }
  .site_header {
    padding: 20px 0;
  }
  .main_menu {
    top: 82px;
  }
  .site_header.sticky .main_menu {
    top: 62px;
  }
  .site_header_1 + main {
    padding-top: 82px;
  }
  .hero_banner.style_1 .content_wrap {
    padding: 80px 20px;
  }
  .hero_banner .banner_big_title {
    font-size: 42px;
    line-height: 44px;
  }
  .banner_image_1 .satisfied_student {
    left: 20px;
    padding: 15px;
  }
  .hero_banner.style_1 {
    margin-bottom: 0;
  }
  .testimonial_item {
    display: block;
  }
  .testimonial_item .testimonial_image {
    max-width: 100%;
    margin: auto auto 20px auto;
  }
  .course_card .item_price .sale_price {
    font-size: 22px;
    line-height: 28px;
  }
  .pricing_card {
    padding: 32px 30px 40px;
  }
  .pricing_card.bg_dark {
    padding: 62px 30px 60px;
  }
  .brands_logo_list a img {
    max-width: 120px;
    max-height: 50px;
  }
  .brands_logo_list a {
    height: 130px;
  }
  .calltoaction_form {
    padding: 30px 20px;
  }
  .page_title {
    font-size: 54px;
    line-height: 60px;
  }
  .page_banner .content_wrapper {
    padding: 70px 25px;
    background-size: 230px;
  }
  .iconbox_item .title_wrap {
    display: block;
  }
  .iconbox_item .title_wrap .item_icon {
    margin-bottom: 18px;
  }
  .iconbox_item .title_wrap {
    margin-bottom: 10px;
  }
  .backtotop {
    bottom: 100px;
  }
  .comment_item .reply_btn {
    position: static;
    margin-top: 20px;
  }
  .remenber_forget {
    display: block !important;
  }
  .remenber_forget > .col {
    width: 100%;
  }
  .forget_password.text-end {
    text-align: left !important;
  }
  .section_heading .heading_text {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 18px;
  }
  .page_banner .content_wrapper {
    min-height: auto;
  }
  .section_space_lg {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 375px) {
  .course_card .item_content .d-flex {
    display: block !important;
  }
  .course_card .item_price {
    margin-top: 10px;
  }
  .banner_image_2 .image_wrap_1 {
    margin-bottom: -540px;
  }
  .banner_image_2 .child_image .layer {
    max-width: 100px;
  }
  .brands_logo_list > li {
    width: 100%;
  }
  .main_menu {
    top: 77px;
  }
  .site_header.sticky .main_menu {
    top: 56px;
  }
  .page_title {
    font-size: 36px;
    line-height: 40px;
  }
  .breadcrumb_nav {
    margin-bottom: 16px;
  }
  .page_banner .content_wrapper {
    margin: 0 -15px;
    padding: 60px 20px;
  }
  .page_banner .item_price .sale_price {
    font-size: 38px;
  }
  .page_banner .item_price .remove_price {
    font-size: 20px;
  }
  .page_banner .info_list,
  .page_banner .meta_info_list {
    padding: 14px 0 24px;
  }
}
@media screen and (max-width: 320px) {
  .main_menu {
    top: 73px;
  }
  .site_header.sticky .main_menu {
    top: 52px;
  }
} /*!


.faa-parent.animated-hover:hover > .faa-spin,
.faa-spin.animated,
.faa-spin.animated-hover:hover {
  animation: spin 1.5s linear infinite;
} /*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}
*,
::after,
::before {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
hr:not([size]) {
  height: 1px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem;
  }
}
.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2rem;
  }
}
.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}
.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-bs-original-title],
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
.small,
small {
  font-size: 0.875em;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer !important;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]::-webkit-calendar-picker-indicator {
  display: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  cursor: pointer !important;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::file-selector-button {
  font: inherit;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}
.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}
.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}
.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}
.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}
.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  
  
}

.form-label {
  margin-bottom: 0.5rem;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}
textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}
textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}
.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}
.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}
.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}
.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: #198754;
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #198754;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #198754;
}
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #198754;
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
  z-index: 1;
}
.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: #dc3545;
}
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #dc3545;
}
.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #dc3545;
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
  z-index: 2;
}
.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}
.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #0d6efd;
  background-color: transparent;
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #198754;
  background-color: transparent;
}
.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #0dcaf0;
  background-color: transparent;
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #212529;
  background-color: transparent;
}
.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}
.btn-link:hover {
  color: #0a58ca;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}
.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:focus,
.nav-link:hover {
  color: #0a58ca;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}
.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.card-title {
  margin-bottom: 0.5rem;
}
.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}
.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-body {
  padding: 1rem 1.25rem;
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/");
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}
.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-link {
  padding: 0.375rem 0.75rem;
}
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}
.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}
.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}
.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}
.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}
.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}
.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}
.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close.disabled,
.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}
.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}
.toast-container {
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}
.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[data-popper-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[data-popper-placement^="right"],
.bs-tooltip-end {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[data-popper-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[data-popper-placement^="left"],
.bs-tooltip-start {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::after,
.popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
.bs-popover-top > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
.bs-popover-end > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
.bs-popover-start > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}
.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}
.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}
.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}
.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}
.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}
.offcanvas.show {
  transform: none;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.link-primary {
  color: #0d6efd;
}
.link-primary:focus,
.link-primary:hover {
  color: #0a58ca;
}
.link-secondary {
  color: #6c757d;
}
.link-secondary:focus,
.link-secondary:hover {
  color: #565e64;
}
.link-success {
  color: #198754;
}
.link-success:focus,
.link-success:hover {
  color: #146c43;
}
.link-info {
  color: #0dcaf0;
}
.link-info:focus,
.link-info:hover {
  color: #3dd5f3;
}
.link-warning {
  color: #ffc107;
}
.link-warning:focus,
.link-warning:hover {
  color: #ffcd39;
}
.link-danger {
  color: #dc3545;
}
.link-danger:focus,
.link-danger:hover {
  color: #b02a37;
}
.link-light {
  color: #f8f9fa;
}
.link-light:focus,
.link-light:hover {
  color: #f9fafb;
}
.link-dark {
  color: #212529;
}
.link-dark:focus,
.link-dark:hover {
  color: #1a1e21;
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}
.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}
.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}

.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.translate-middle {
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  transform: translateY(-50%) !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: 1px solid #dee2e6 !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: 1px solid #dee2e6 !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #0d6efd !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #198754 !important;
}
.border-info {
  border-color: #0dcaf0 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #212529 !important;
}
.border-white {
  border-color: #fff !important;
}
.border-1 {
  border-width: 1px !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 3rem !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 3rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 3rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 3rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 3rem !important;
}
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}
.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}
.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.fs-5 {
  font-size: 1.25rem !important;
}
.fs-6 {
  font-size: 1rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 2 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  color: #0d6efd !important;
}
.text-secondary {
  color: #6c757d !important;
}
.text-success {
  color: #198754 !important;
}
.text-info {
  color: #0dcaf0 !important;
}
.text-warning {
  color: #ffc107 !important;
}
.text-danger {
  color: #dc3545 !important;
}
.text-light {
  color: #f8f9fa !important;
}
.text-dark {
  color: #212529 !important;
}
.text-white {
  color: #fff !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-reset {
  color: inherit !important;
}
.bg-primary {
  background-color: #0d6efd !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
.bg-success {
  background-color: #198754 !important;
}
.bg-info {
  background-color: #0dcaf0 !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
.bg-dark {
  background-color: #212529 !important;
}
.bg-body {
  background-color: #fff !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: 0.2rem !important;
}
.rounded-2 {
  border-radius: 0.25rem !important;
}
.rounded-3 {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@keyframes mdp-float-aura {
  from {
    box-shadow: 0 0 0 -1px var(--hmp-color-75), 0 0 0 -1px var(--hmp-color-50),
      0 0 0 -1px var(--hmp-color-25);
  }
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0),
      0 0 0 30px rgba(255, 255, 255, 0), 0 0 0 45px rgba(255, 255, 255, 0);
  }
}
@keyframes mdp-float-init {
  0% {
    opacity: 0;
    transform: scale(0.25);
  }
  50% {
    opacity: 0;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.mdp-float {
  --hmp-color: rgb(38 205 118 / 0%);
  --hmp-color-75: rgb(38 203 125 / 75%);
  --hmp-color-50: rgba(38 203 125 / 50%);
  --hmp-color-25: rgba(38 203 125 / 25%);
  --hmp-color-5: rgba(38 203 125 / 5%);
  position: fixed;
  z-index: 999999;
  border-radius: 50%;
  bottom: 3em;
  right: 3em;
  width: 60px;
  height: 60px;
  transition: 0.75s;
  transition-timing-function: ease-out;
}
.mdp-float:hover {
  animation: mdp-float-aura infinite;
  animation-duration: 2s;
}
.mdp-float.mdp-float-inertia {
  transition: 0.25s;
}
.mdp-float.mdp-float-slow {
  transition-delay: 0.5s;
  transition: 5s;
}
.mdp-float.mdp-float-init {
  animation: mdp-float-init 1s;
  animation-fill-mode: both;
}
.mdp-float-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  text-shadow: 1px 0 0 var(--hmp-color);
  background-image: linear-gradient(
    210deg,
    var(--hmp-color-5) 0,
    var(--hmp-color-25) 100%
  );
  background-color: #fff;
  transition: 0.25s;
  box-shadow: 0 7px 13px -3px var(--hmp-color-25),
    0 2px 4px 0 var(--hmp-color-50), inset 0 0 0 0 var(--hmp-color);
  position: relative;
}
.mdp-float-btn:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--hmp-color);
  border-radius: 50%;
  transform: scale(0);
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}

.mdp-float-btn:focus {
  outline: 0;
}
.mdp-float-btn:hover {
  transition: 0.25s;
  transition-delay: 125ms;
  box-shadow: 0 7px 26px -3px var(--hmp-color-25),
    0 2px 8px 0 var(--hmp-color-50);
  border: none;
}
.mdp-float-btn:hover:before {
  transform: scale(1);
}

.mdp-float-btn:active {
  transform: scale(0.75);
}
/* @media (max-width: 720px) {
  .mdp-float {
    top: 1.5em;
    right: 1.5em;
  }
} */


.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}
@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  transform: rotate(90deg);
}
.fa-rotate-180 {
  transform: rotate(180deg);
}
.fa-rotate-270 {
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  transform: scale(1, -1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1);
}
:root .fa-flip-both,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-rotate-90 {
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
.fa-500px:before {
  content: "\f26e";
}
.fa-instagram:before {
  content: "\f16d"
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-card:before {
  content: "\f2bb";
}
.fa-bars:before {
  content: "\f0c9"
}
.fa-angel:before {
  content: "\f779";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}

.fa-archive:before {
  content: "\f187";
}
.fa-archway:before {
  content: "\f557";
}
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}
.fa-arrow-alt-down:before {
  content: "\f354";
}
.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}
.fa-arrow-alt-from-left:before {
  content: "\f347";
}
.fa-arrow-alt-from-right:before {
  content: "\f348";
}
.fa-arrow-alt-from-top:before {
  content: "\f349";
}
.fa-arrow-alt-left:before {
  content: "\f355";
}
.fa-arrow-alt-right:before {
  content: "\f356";
}
.fa-arrow-alt-square-down:before {
  content: "\f350";
}
.fa-arrow-alt-square-left:before {
  content: "\f351";
}
.fa-arrow-alt-square-right:before {
  content: "\f352";
}
.fa-arrow-alt-square-up:before {
  content: "\f353";
}
.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}
.fa-arrow-alt-to-left:before {
  content: "\f34b";
}
.fa-arrow-alt-to-right:before {
  content: "\f34c";
}
.fa-arrow-alt-to-top:before {
  content: "\f34d";
}
.fa-arrow-alt-up:before {
  content: "\f357";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-arrow-from-bottom:before {
  content: "\f342";
}
.fa-arrow-from-left:before {
  content: "\f343";
}
.fa-arrow-from-right:before {
  content: "\f344";
}
.fa-arrow-from-top:before {
  content: "\f345";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-square-down:before {
  content: "\f339";
}
.fa-arrow-square-left:before {
  content: "\f33a";
}
.fa-arrow-square-right:before {
  content: "\f33b";
}
.fa-arrow-square-up:before {
  content: "\f33c";
}
.fa-arrow-to-bottom:before {
  content: "\f33d";
}
.fa-arrow-to-left:before {
  content: "\f33e";
}
.fa-arrow-to-right:before {
  content: "\f340";
}
.fa-arrow-to-top:before {
  content: "\f341";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-arrows-alt-h:before {
  content: "\f337";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-arrows-h:before {
  content: "\f07e";
}


.fa-blog:before {
  content: "\f781";
}


.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-bold:before {
  content: "\f032";
}




.fa-calendar-day:before {
  content: "\f783";
}

.fa-caret-circle-down:before {
  content: "\f32d";
}
.fa-caret-circle-left:before {
  content: "\f32e";
}
.fa-caret-circle-right:before {
  content: "\f330";
}
.fa-caret-circle-up:before {
  content: "\f331";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-caret-square-down:before {
  content: "\f150";
}
.fa-caret-square-left:before {
  content: "\f191";
}
.fa-caret-square-right:before {
  content: "\f152";
}
.fa-caret-square-up:before {
  content: "\f151";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-carrot:before {
  content: "\f787";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cash-register:before {
  content: "\f788";
}
.fa-cat:before {
  content: "\f6be";
}
.fa-cauldron:before {
  content: "\f6bf";
}
.fa-cc-amazon-pay:before {
  content: "\f42d";
}
.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-check:before {
  content: "\f00c";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-check-double:before {
  content: "\f560";
}
.fa-check-square:before {
  content: "\f14a";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-double-down:before {
  content: "\f322";
}
.fa-chevron-double-left:before {
  content: "\f323";
}
.fa-chevron-double-right:before {
  content: "\f324";
}
.fa-chevron-double-up:before {
  content: "\f325";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-square-down:before {
  content: "\f329";
}
.fa-chevron-square-left:before {
  content: "\f32a";
}
.fa-chevron-square-right:before {
  content: "\f32b";
}
.fa-chevron-square-up:before {
  content: "\f32c";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-child:before {
  content: "\f1ae";
}

.fa-circle:before {
  content: "\f111";
}
.fa-circle-notch:before {
  content: "\f1ce";
}
.fa-city:before {
  content: "\f64f";
}
.fa-claw-marks:before {
  content: "\f6c2";
}

.fa-code:before {
  content: "\f121";
}
.fa-code-branch:before {
  content: "\f126";
}
.fa-code-commit:before {
  content: "\f386";
}
.fa-code-merge:before {
  content: "\f387";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-codiepie:before {
  content: "\f284";
}

.fa-columns:before {
  content: "\f0db";
}


.fa-copyright:before {
  content: "\f1f9";
}



.fa-dot-circle:before {
  content: "\f192";
}
.fa-dove:before {
  content: "\f4ba";
}


.fa-envelope:before {
  content: "\f0e0";
}



.fa-eye:before {
  content: "\f06e";
}
.fa-eye-dropper:before {
  content: "\f1fb";
}
.fa-eye-evil:before {
  content: "\f6db";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-facebook-square:before {
  content: "\f082";
}



.fa-line:before {
  content: "\f3c0";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-linkedin:before {
  content: "\f08c";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-location-dot:before {
  content: "\f3c5";
}
.fa-location:before {
  content: "\f601";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-location-circle:before {
  content: "\f602";
}
.fa-location-slash:before {
  content: "\f603";
}
.fa-lock:before {
  content: "\f023";
}
.fa-lock-alt:before {
  content: "\f30d";
}
.fa-lock-open:before {
  content: "\f3c1";
}
.fa-lock-open-alt:before {
  content: "\f3c2";
}
.fa-long-arrow-alt-down:before {
  content: "\f309";
}
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-loveseat:before {
  content: "\f4cc";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-luchador:before {
  content: "\f455";
}
.fa-luggage-cart:before {
  content: "\f59d";
}




.fa-phone:before {
  content: "\f095";
}
.fa-phone-office:before {
  content: "\f67d";
}
.fa-phone-plus:before {
  content: "\f4d2";
}
.fa-phone-slash:before {
  content: "\f3dd";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-play:before {
  content: "\f04b";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-playstation:before {
  content: "\f3df";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-plus:before {
  content: "\f067";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-plus-hexagon:before {
  content: "\f300";
}
.fa-plus-octagon:before {
  content: "\f301";
}
.fa-plus-square:before {
  content: "\f0fe";
}



.fa-scroll:before {
  content: "\f70e";
}
.fa-scroll-old:before {
  content: "\f70f";
}





.fa-spray-can:before {
  content: "\f5bd";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-square-full:before {
  content: "\f45c";
}
.fa-square-root:before {
  content: "\f697";
}
.fa-square-root-alt:before {
  content: "\f698";
}
.fa-squarespace:before {
  content: "\f5be";
}
.fa-squirrel:before {
  content: "\f71a";
}

.fa-stamp:before {
  content: "\f5bf";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-and-crescent:before {
  content: "\f699";
}
.fa-star-christmas:before {
  content: "\f7d4";
}
.fa-star-exclamation:before {
  content: "\f2f3";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-star-half-alt:before {
  content: "\f5c0";
}
.fa-star-of-david:before {
  content: "\f69a";
}
.fa-star-of-life:before {
  content: "\f621";
}
.fa-stars:before {
  content: "\f762";
}

.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}

.fa-telegram:before {
  content: "\f2c6";
}
.fa-telegram-plane:before {
  content: "\f3fe";
}


.fa-times:before {
  content: "\f00d";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-times-hexagon:before {
  content: "\f2ee";
}
.fa-times-octagon:before {
  content: "\f2f0";
}
.fa-times-square:before {
  content: "\f2d3";
}

.fa-trash:before {
  content: "\f1f8";
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.fa-trash-restore:before {
  content: "\f829";
}
.fa-trash-restore-alt:before {
  content: "\f82a";
}

.fa-twitch:before {
  content: "\f1e8";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-typo3:before {
  content: "\f42b";
}

.fa-underline:before {
  content: "\f0cd";
}
.fa-undo:before {
  content: "\f0e2";
}
.fa-undo-alt:before {
  content: "\f2ea";
}


.fa-upload:before {
  content: "\f093";
}

.fa-user:before {
  content: "\f007";
}
.fa-user-alt:before {
  content: "\f406";
}
.fa-user-alt-slash:before {
  content: "\f4fa";
}
.fa-user-astronaut:before {
  content: "\f4fb";
}
.fa-user-chart:before {
  content: "\f6a3";
}
.fa-user-check:before {
  content: "\f4fc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-clock:before {
  content: "\f4fd";
}
.fa-user-cog:before {
  content: "\f4fe";
}
.fa-user-crown:before {
  content: "\f6a4";
}
.fa-user-edit:before {
  content: "\f4ff";
}
.fa-user-friends:before {
  content: "\f500";
}
.fa-user-graduate:before {
  content: "\f501";
}
.fa-user-hard-hat:before {
  content: "\f82c";
}
.fa-user-headset:before {
  content: "\f82d";
}
.fa-user-injured:before {
  content: "\f728";
}
.fa-user-lock:before {
  content: "\f502";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-user-md-chat:before {
  content: "\f82e";
}
.fa-user-minus:before {
  content: "\f503";
}
.fa-user-ninja:before {
  content: "\f504";
}
.fa-user-nurse:before {
  content: "\f82f";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-user-shield:before {
  content: "\f505";
}
.fa-user-slash:before {
  content: "\f506";
}
.fa-user-tag:before {
  content: "\f507";
}
.fa-user-tie:before {
  content: "\f508";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-users-class:before {
  content: "\f63d";
}
.fa-users-cog:before {
  content: "\f509";
}
.fa-users-crown:before {
  content: "\f6a5";
}
.fa-users-medical:before {
  content: "\f830";
}




.fa-whatsapp:before {
  content: "\f232";
}
.fa-whatsapp-square:before {
  content: "\f40c";
}


.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-square:before {
  content: "\f431";
}
.fa-zhihu:before {
  content: "\f63f";
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(../public/assets/fonts/fa-brands-400.ttf) format("truetype");
}
.fab {
  font-family: "Font Awesome 5 Brands";
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(../public/assets/fonts/fa-light-300.ttf) format("truetype");
}
.fal {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(../public/assets/fonts/fa-regular-400.ttf) format("truetype");
}
.far {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(../public/assets/fonts/fa-solid-900.ttf) format("truetype");
}
.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
} 




/* @font-face {
  font-family: slick;
  font-weight: 400;
  font-style: normal;
  src: url(../public/assets/fonts/slick.eot);
  src: url(../public/assets/fonts/slick.eot?#iefix) format("embedded-opentype"),
    url(../public/assets/fonts/slick.woff) format("woff"),
    url(../public/assets/fonts/slick.ttf) format("truetype"),
    url(../public/assets/fonts/slick.svg#slick) format("svg");
} */
.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;

  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;

  outline: 0;
  background: 0 0;
  border: 1px solid black;
  border-radius: 50%;
  width: 40px;
    height: 40px;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: #000;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -42px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}
.slick-next {
  right: -42px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.65;
  font-style: normal;
  color: #29281e;
  font-family: Roboto, sans-serif;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
} */
.page_wrapper {
  overflow: hidden;
  position: relative;
}
[disabled] {
  opacity: 0.4;
  pointer-events: none;
}
iframe {
  border: none;
}
a:active,
a:focus,
input,
input:active,
input:focus,
input:hover,
textarea,
textarea:active,
textarea:focus,
textarea:hover {
  outline: 0;
}
embed,
img:not([draggable]),
object,
video {
  height: auto;
  max-width: 100%;
}
img {
  border: none;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
a {
  outline: 0;
  display: inline-block;
  text-decoration: none;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
a:active,
a:focus,
a:hover,
a:visited {
  outline: 0;
  text-decoration: none;
}
button {
  padding: 0;
  border: none;
  outline: 0;
  background: 0 0;
  display: inline-block;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
button:focus {
  outline: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
hr {
  opacity: 1;
  margin: 25px 0;
  background-color: #d2d2d2;
}
.fa {
  font-family: "Font Awesome 5 Brands";
}
.container {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  margin: -15px;
}
.col {
  flex: unset;
  padding: 15px;
}
.dropdown-menu {
  border: none;
  margin-top: 20px;
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
  animation: 0.3s ease-in-out 0s normal none 1 running fadeInUp;
}
.dropdown-menu:before {
  left: 0;
  right: 0;
  top: -20px;
  content: "";
  height: 20px;
  display: block;
  position: absolute;
}
.dropdown-toggle::after {
  margin: 0;
  border: none;
  line-height: 1;
  content: "\f107";
  font-weight: 400;
  color: var(--primary-dark);
  font-family: "Font Awesome 5 Pro";
}
.layer {
  position: relative !important;
}
.bg_light {
  background-color: #f6f6f6;
}
.bg_light_2 {
  background-color: #f8f8f8;
}
.bg_dark {
  background-color: #1f1d0d;
}
.bg_primary {
  background-color: var(--primary-light);
}
.bg_facebook {
  background-color: #3b5998;
}
.bg_twitter {
  background-color: #1da1f2;
}
.bg_instagram {
  background-color: #c32aa3;
}
.bg_linkedin {
  background-color: #0a66c2;
}
@keyframes dropDown {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


.decoration_wrap {
  z-index: 1;
  position: relative;
}
.decoration_wrap .deco_item {
  z-index: -1;
  position: absolute;
}
.decoration_wrap .overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: absolute;
}
.decoration_wrap .half_bg_top {
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  position: absolute;
}
.section_space_lg {
  padding-top: 120px;
  padding-bottom: 120px;
}
/* .section_space_md {
  padding-top: 70px;
  padding-bottom: 70px;
} */
.btn_wrap {
  padding: 44px 0;
}
.btn {
  border: none;
  line-height: 1;
  padding: 0 40px;
  overflow: hidden;
  position: relative;
  color: #fff;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  border: 2px solid transparent;
  border-radius: 8px;
}
.btn span {
  position: relative;
  display: inline-block;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.btn span small {
  display: block;
  padding: 16px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.btn span small:nth-child(2) {
  left: 50%;
  top: 100%;
  opacity: 0;
  width: 100%;
  position: absolute;
  transform: translateX(-50%);
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn:hover {
  z-index: 1;
  color: #fff;
  transform: translateY(-4px);
  box-shadow: 0 8px 24px 0 rgba(16, 18, 19, 0.4);
}
.btn:hover span small {
  color: #fff;
}
.btn:hover span small:nth-child(1) {
  opacity: 0;
  transform: translateY(-100%);
}
.btn:hover span small:nth-child(2) {
  top: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
}
.btn.btn_small {
  padding: 0 28px;
}
.btn.btn_small span small {
  padding: 9px 0;
}
.btn_primary {
  color: #1f1d0d;
  border-color: var(--primary-light);
  background-color: var(--primary-light);
}
.btn_primary:hover {
  color: #1f1d0d;
  box-shadow: 0 8px 24px 0 rgba(247, 255, 98, 0.4);
}
.btn_primary:hover span small {
  color: #1f1d0d;
}
.btn_dark {
  color: #fff;
  border-color: #1f1d0d;
  background-color: #1f1d0d;
}
.btn_dark:hover {
  color: #fff;
}
.btn_dark:hover span small {
  color: #fff;
}
.btn_warning {
  color: #1f1d0d;
  border-color: var(--primary-dark);
  background-color: var(--primary-dark);
}
.btn_warning:hover {
  color: #1f1d0d;
  box-shadow: 0 8px 24px 0 rgba(255, 211, 43, 0.5);
}
.btn_warning:hover span small {
  color: #1f1d0d;
}
.btn.border_dark {
  color: #1f1d0d;
  border-color: #1f1d0d;
}
.btn.border_dark:hover {
  color: #fff;
  background-color: #1f1d0d;
}
.btn.border_dark:hover span small {
  color: #fff;
}
.btn_unfill {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1f1d0d;
  align-items: center;
  display: inline-flex;
  font-family: Barlow, sans-serif;
}
.btn_unfill .btn_icon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  margin-left: 10px;
  position: relative;
  border-radius: 100%;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: #1f1d0d;
}
.btn_unfill .btn_icon i {
  top: 50%;
  font-size: 14px;
  position: absolute;
  color: var(--primary-light);
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.btn_unfill .btn_icon i:nth-child(1) {
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn_unfill .btn_icon i:nth-child(2) {
  left: -100%;
  transform: translateY(-50%);
}
.btn_unfill:hover {
  color: #1f1d0d;
}
.btn_unfill:hover span {
  text-decoration: underline;
}
.btn_unfill:hover .btn_icon {
  transform: translateX(2px);
}
.btn_unfill:hover .btn_icon i:nth-child(1) {
  left: 100%;
  transform: translateY(-50%);
}
.btn_unfill:hover .btn_icon i:nth-child(2) {
  left: 50%;
  transform: translate(-50%, -50%);
}
.unordered_list,
.unordered_list_center,
.unordered_list_end {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.unordered_list > li,
.unordered_list_center > li,
.unordered_list_end > li {
  float: left;
  list-style: none;
  display: inline-block;
}
.unordered_list {
  justify-content: flex-start;
}
.unordered_list_center {
  justify-content: center;
}
.unordered_list_end {
  justify-content: flex-end;
}
.unordered_list_block {
  margin: 0;
  padding: 0;
  display: block;
}
.unordered_list_block > li {
  display: block;
  list-style: none;
}
ol[type="1"] > li:not(:last-child) {
  margin-bottom: 12px;
}
.info_list.unordered_list_block > li:not(:last-child) {
  margin-bottom: 12px;
}
.info_list a {
  display: block;
  color: #29281e;
}
.info_list a:hover {
  opacity: 0.7;
}

.info_list i[class*="check"] {
  font-size: 14px;
  text-shadow: none;
  color: var(--primary-dark);
}
.info_list span {
  display: table;
}
.page_list.unordered_list {
  margin: -5px -10px;
}
.page_list.unordered_list > li {
  padding: 5px 10px;
}
.page_list.unordered_list_block > li:not(:last-child) {
  margin-bottom: 10px;
}
.page_list > a {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tags_list {
  margin: -10px;
}
.tags_list > li {
  padding: 10px;
}
.tags_list a {
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #1f1d0d;
  padding: 12px 16px;
  text-transform: uppercase;
  font-family: Barlow, sans-serif;
  border-radius: 8px;
  background-color: #fff;
}
.tags_list a:hover {
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 0 0 #1f1d0d;
}
.tags_list.style_2 a {
  background-color: #f6f6f6;
}
.item_category_list {
  overflow: hidden;
  border-radius: 8px;
}
.item_category_list span {
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  padding: 7px 12px;
  color: #1f1d0d;
  text-transform: uppercase;
  font-family: Barlow, sans-serif;
  background-color: #f6f6f6;
}
.item_category_list span:hover {
  background-color: var(--primary-dark);
}
.meta_info_list {
  margin: -2px -6px;
}
.meta_info_list > li {
  font-size: 14px;
  padding: 2px 6px;
  line-height: 20px;
}

.meta_info_list i {
  margin-right: 2px;
  color: var(--primary-dark);
}

.meta_info_list.unordered_list_block > li:not(:last-child) {
  margin-bottom: 8px;
}
.section_heading {
  margin-bottom: 40px;
}
.section_heading .heading_text {
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 24px;
}
.section_heading .heading_description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
blockquote {
  display: flex;
  margin-bottom: 20px;
}
blockquote .blockquote_icon {
  flex: 0 0 60px;
  margin-right: 30px;
}
blockquote p {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
  font-family: Barlow, sans-serif;
}
.slick-dots {
  margin: 0;
  display: flex;
  position: static;

  align-items: center;
  justify-content: center;
}
.slick-dots li {
  width: auto;
  height: auto;
  margin: 0 5px;
  display: inline-block;
}
.slick-dots li button {
  margin: 0;
  padding: 0;
  opacity: 0.3;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 100%;
  background-color: #6a6f08;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.slick-dots li button:before {
  display: none;
}
.slick-dots li.slick-active button,
.slick-dots li:hover button {
  opacity: 1;
}
.slick-dotted.slick-slider {
  margin-bottom: 0;
}



.image_widget {
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  box-shadow: -20px 20px 0 0 var(--primary-dark);
}



.video_play_btn {
  display: flex;
  align-items: center;
}
[dir="rtl"] .video_play_btn .icon{
  margin-left:10px;
}
.video_play_btn .icon {
  width: 60px;
  height: 60px;
  display: flex;
  flex: 0 0 60px;
  font-size: 18px;
  color: #1f1d0d;
  margin-right: 10px;
  margin-left:0px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  border: 2px solid #1f1d0d;
}
.video_play_btn .icon i {
  margin-left: 4px;
}
.video_play_btn .icon:hover {
  color: #1f1d0d;
  border-color: var(--primary-dark);
  background-color: var(--primary-dark);
}
.video_play_btn .text {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  font-family: Barlow, sans-serif;
}
.video_play_btn.text-white .icon {
  color: #fff;
  border: 2px solid #fff;
}
.video_play_btn.text-white .icon:hover {
  color: #1f1d0d;
  border-color: var(--primary-dark);
  background-color: var(--primary-dark);
}
.video_play_btn.text-white .text {
  color: #fff;
}
.intro_video_section .shape_img_1 {
  top: -15px;
  right: -147px;
  max-width: 102px;
}
.intro_video_section .shape_img_2 {
  top: 30%;
  left: -120px;
  max-width: 270px;
}
.intro_video_section .shape_img_3 {
  right: -220px;
  bottom: -200px;
  max-width: 497px;
}
.intro_video {
  margin-bottom: 50px;
}
.intro_video .video_wrap {
  z-index: 1;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.intro_video .video_wrap .video_play_btn {
  top: 50%;
  left: 50%;
  z-index: 2;
  position: absolute;
  transform: translate(-50%, -50%);
}
.form_item {
  position: relative;
  margin-bottom: 12px;
}
.form_item input,
.form_item select,
.form_item textarea {
  width: 100%;
  outline: 0;
  display: block;
  box-shadow: none;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  border-radius: 8px;
  border: 2px solid #d2d2d2;
}
.form_item input:focus,
.form_item select:focus,
.form_item textarea:focus {
  outline: 0;
  box-shadow: none;
}
.form_item input {
  height: 40px;
  padding: 0 20px;
}
.form_item textarea {
  min-height: 50px;
  padding: 15px 20px;
}
.form_item select {
  height: 45px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: 12px;
  padding: 0 40px 0 20px;
  color: rgba(31, 29, 13, 0.5);
  background-repeat: no-repeat;
  background-position: right 20px center;
  /* background-image: url(.../public/assets/images/icon/icon_down.png); */
}
.form_item:has(.btn) {
  display: flex;
  margin-bottom: 60px;
}
.form_item:has(.btn) input {
  flex: 0 0 370px;
  margin-right: 20px;
  border-color: #1f1d0d;
}
.input_title {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #1f1d0d;
  margin-bottom: 6px;
  font-family: Barlow, sans-serif;
}
:-moz-placeholder {
  color: rgba(31, 29, 13, 0.5);
}
::-moz-placeholder {
  color: rgba(31, 29, 13, 0.5);
}
:-ms-input-placeholder {
  color: rgba(31, 29, 13, 0.5);
}
::-webkit-input-placeholder {
  color: rgba(31, 29, 13, 0.5);
}
.checkbox_item {
  position: relative;
  padding-left: 26px;
  margin-bottom: 30px;
}
.checkbox_item input {
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  border-radius: 8px;
  border: 1px solid #1f1d0d;
  background-color: #fff;
}
.checkbox_item input:before {
  top: 50%;
  left: 50%;
  opacity: 0;
  line-height: 1;
  font-size: 8px;
  content: "\f00c";
  font-weight: 700;
  position: absolute;
  transform: translate(-50%, -50%);
  font-family: "Font Awesome 5 Pro";
}
.checkbox_item input:checked {
  background-color: var(--primary-light);
}
.checkbox_item input:checked:before {
  opacity: 1;
}
.checkbox_item label {
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
}

.tabs_wrapper .nav {
  margin: -10px -10px 30px;
}
.tabs_wrapper .nav > li {
  padding: 10px;
}
.tabs_wrapper button {
  color: #1f1d0d;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 16px;
  text-transform: uppercase;
  font-family: Barlow, sans-serif;
  border-radius: 8px;
  border: 2px solid #d2d2d2;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.tabs_wrapper button i {
  margin-right: 4px;
}
.tabs_wrapper button.active,
.tabs_wrapper button:hover {
  color: #fff;
  border-color: #1f1d0d;
  background-color: #1f1d0d;
}
.social_links {
  margin: -6px;
}
.social_links > li {
  padding: 6px;
}
.social_links a {
  z-index: 1;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}
.social_links a:after,
.social_links a:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: -1;
  position: absolute;
  border-radius: 100%;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.social_links a:before {
  border: 2px solid var(--primary-dark);
}
.social_links a:after {
  background-color: var(--primary-dark);
}
.social_links a i {
  color: #1f1d0d;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.social_links a:hover:before {
  transform: scale(1.1);
}
.social_links a:hover:after {
  transform: scale(0.8);
}
.counter_item {
  padding: 0 34px 24px 0;
  border-bottom: 1px solid #d2d2d2;
}

.counter_item p {
  font-size: 16px;
  line-height: 24px;
}
.counter_section.bg_primary .counter_item {
  border-bottom: 1px solid #29281e;
}
.rating_wrap {
  display: flex;
  align-items: center;
}
.rating_wrap .review_counter {
  font-size: 12px;
  margin-left: 5px;
}
.rating_star li {
  line-height: 1;
  font-size: 12px;
}
.rating_star li:not(:last-child) {
  margin-right: 2px;
}
.rating_star li.active {
  color: #ffda47;
}
.iconbox_item .serial_number {
  font-size: 65px;
  font-weight: 800;
  line-height: 68px;
  font-family: Barlow, sans-serif;
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1f1d0d;
}
.iconbox_item hr {
  margin: 15px 0 14px;
}
.iconbox_item .title_wrap {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.iconbox_item .item_icon {
  width: 50px;
  height: 50px;
  padding: 8px;
  flex: 0 0 50px;
  line-height: 1;
  font-size: 18px;
  position: relative;
  margin-right: 18px;
  margin-left:0;
  display: inline-flex;
  align-items: flex-end;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 3px 3px 0 0 var(--primary-dark);
}
[dir="rtl"] .iconbox_item .item_icon{
  margin-left: 18px;
  margin-right:0px
}
.iconbox_item .item_icon.bg_dark {
  align-items: center;
  color: var(--primary-dark);
  justify-content: center;
  background-color: #1f1d0d;
}
.iconbox_item .item_title {
  font-size: 22px;
  line-height: 25px;
}
.iconbox_item p {
  font-size: 14px;
  line-height: 22px;
}
.accordion .accordion-item {
  border: none;
  padding: 30px 0;
  border-radius: 0;
  position: relative;
  background-color: transparent;
  border-bottom: 1px solid #d2d2d2;
}
.accordion .accordion-button {
  padding: 0;
  font-size: 28px;
  font-weight: 700;
  box-shadow: none;
  line-height: 32px;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
  background-color: transparent;
}
.accordion .accordion-button:after {
  width: 50px;
  height: 50px;
  font-size: 24px;
  content: "\f067";
  font-weight: 300;
  background: 0 0;
  margin-left: auto;
  text-align: center;
  line-height: 52px;
  border-radius: 100%;
  color: var(--primary-light);
  background-color: #1f1d0d;
  transition: all 0.1s ease-in-out;
  font-family: "Font Awesome 5 Pro";
}
[dir="rtl"] .accordion .accordion-button:after
{
  margin-left: 0;
  margin-right:auto;
}
.accordion .accordion-button:not(.collapsed)::after {
  content: "\f068";
  transform: rotate(0);
}
.accordion .accordion-body {
  padding: 20px 0 0;
}
.accordion p {
  font-size: 16px;
  line-height: 24px;
}
.accordion .completed_progress {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}
.accordion .checkbox_item {
  margin: 0;
}
.accordion .checkbox_item:not(:last-child) {
  margin-bottom: 20px;
}
.accordion .checkbox_item label {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1f1d0d;
}
.accordion .accordion-item:has(.accordion_item_checked) {
  padding-left: 30px;
}
.accordion
  .accordion-item:has(.accordion_item_checked)
  .accordion_item_checked {
  left: 0;
  top: 47px;
  margin: 0;
  padding: 0;
  z-index: 2;
  position: absolute;
}
.accordion
  .accordion-item:has(.accordion_item_checked)
  .accordion_item_checked
  input {
  display: block;
  position: static;
}
.accordion.style_2 {
  padding: 0 30px;
  border-radius: 8px;
  border: 1px solid #d2d2d2;
}
.accordion.style_2 .accordion-item {
  border: none;
  padding: 30px 0;
}
.accordion.style_2 .accordion-item:not(:last-child) {
  border-bottom: 1px solid #d2d2d2;
}
.accordion.style_2 .accordion-button {
  font-size: 22px;
  line-height: 25px;
}

.accordion.style_2 .accordion-item:has(.accordion_item_checked) {
  padding-left: 25px;
}
.accordion.style_2
  .accordion-item:has(.accordion_item_checked)
  .accordion_item_checked {
  top: 35px;
}
.pagination_wrap {
  padding-top: 50px;
}
.pagination_nav {
  margin: -6px;
}
.pagination_nav li {
  padding: 6px;
}
.pagination_nav a {
  height: 48px;
  display: block;
  min-width: 48px;
  font-size: 16px;
  font-weight: 700;
  line-height: 46px;
  color: #1f1d0d;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #d2d2d2;
}
.pagination_nav li.active a,
.pagination_nav li:hover a {
  color: #fff;
  border-color: #1f1d0d;
  background-color: #1f1d0d;
}
.deals_countdown {
  display: flex;
  align-items: center;
}
.deals_countdown .discount_value {
  padding: 10px;
  height: 110px;
  min-width: 130px;
  margin-left: 28px;
  color: #1f1d0d;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--primary-light);
}
.deals_countdown .discount_value strong {
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  font-family: Barlow, sans-serif;
}
.deals_countdown .discount_value span {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.deals_countdown .countdown_timer {
  display: inline-flex;
}
.countdown_timer {
  padding: 9px 10px;
  text-align: center;
  justify-content: center;
  border: 1px solid #1f1d0d;
  border-radius: 8px;
  background-color: #fff;
}
.countdown_timer > li {
  padding: 10px;
  min-width: 90px;
  position: relative;
}
.countdown_timer > li:not(:last-child):after {
  top: 5px;
  right: -9px;
  content: ":";
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  position: absolute;
  font-family: Barlow, sans-serif;
}
.countdown_timer strong {
  display: block;
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  font-family: Barlow, sans-serif;
}
.countdown_timer span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.recent_comments_list li {
  color: #29281e;
}
.recent_comments_list li:not(:last-child) {
  margin-bottom: 10px;
}
.recent_comments_list li a {
  color: #1f1d0d;
}
.recent_comments_list li a:hover strong {
  text-decoration: underline;
}
.comments_list > li:not(:last-child) {
  margin-bottom: 30px;
}
.comments_list .comments_list > li {
  padding: 5px 0 0 30px;
}
.comment_item {
  padding: 30px;
  position: relative;
  border-radius: 8px;
  background-color: #f6f6f6;
}
.comment_item .comment_author {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.comment_item .author_thumbnail {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 20px;
}
.comment_item .author_name {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
}
.comment_item .comment_date {
  display: block;
  font-size: 14px;
  line-height: 20px;
}
.comment_item p {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 24px;
}
.comment_item .reply_btn {
  top: 30px;
  right: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  position: absolute;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.comment_item .reply_btn i {
  margin-left: 5px;
}
.comment_item .reply_btn:hover {
  opacity: 0.6;
}
.comment_form_wrap .form_item input,
.comment_form_wrap .form_item textarea {
  border-width: 1px;
  background-color: #f6f6f6;
}
.comment_form_wrap .form_item input:focus,
.comment_form_wrap .form_item textarea:focus {
  background-color: #fff;
}
.comment_form_wrap .btn {
  width: 100%;
  display: block;
}
.hero_banner .banner_small_title {
  font-size: 13px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
  color: #d2d2d2;
}
.hero_banner .banner_big_title {
  font-size: 65px;
  font-weight: 800;
  line-height: 68px;
  color: #eab01c;
  margin-bottom: 20px;
}
.hero_banner .banner_description {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 40px;
}
.hero_banner .banner_btns_group {
  margin: -20px;
}
.hero_banner .banner_btns_group > li {
  padding: 20px;
}
.hero_banner :-moz-placeholder {
  color: #444;
}
.hero_banner ::-moz-placeholder {
  color: #444;
}
.hero_banner :-ms-input-placeholder {
  color: #444;
}
.hero_banner ::-webkit-input-placeholder {
  color: #444;
}
.hero_banner .tags_list {
  max-width: 400px;
}
.hero_banner.style_1 {
  margin-bottom: 59px;
}
.hero_banner.style_1 .container {
  max-width: 1430px;
}
.hero_banner.style_1 .content_wrap {
  padding: 100px;
  background-color: #20313d;
  border-radius: 8px;
}

.banner_image_1 {
  z-index: 1;
  position: relative;
  margin: 0 0 -130px -58px;
}
.banner_image_1 .image_wrap {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}

.banner_image_1 .satisfied_student .wrap_title {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
}
.banner_image_1 .satisfied_student .students_thumbnail > li:not(:first-child) {
  margin-left: -15px;
}
.banner_image_1 .satisfied_student .students_thumbnail span {
  width: 50px;
  height: 50px;
  display: block;
  overflow: hidden;
  border-radius: 100%;
  border: 2px solid #fff;
  background-color: #fff;
}
.banner_image_1 .deco_item {
  z-index: 1;
}
.banner_image_1 .shape_img_1 {
  top: 42%;
  left: -124px;
}
.banner_image_1 .shape_img_2 {
  top: -33px;
  right: -16px;
}
.hero_banner.style_2 {
  overflow: hidden;
  padding: 152px 0 160px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--primary-dark);
  background-position: center center;
}
.hero_banner.style_2 .banner_big_title {
  margin-right: -30px;
}
.hero_banner.style_2 .banner_big_title,
.hero_banner.style_2 .banner_description {
  color: #1f1d0d;
}
.hero_banner.style_2 .banner_description {
  max-width: 500px;
}
.banner_image_2 {
  position: relative;
}
.banner_image_2 .image_wrap_1 {
  margin-bottom: -850px;
}
.banner_image_2 .child_image {
  z-index: 1;
  position: absolute;
}
.banner_image_2 .child_image .layer {
  max-width: 260px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 10px 10px 0 0 #1f1d0d;
}
.banner_image_2 .child_image .layer:before {
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  content: "";
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url(.../public/assets/images/banner/icons_group.svg); */
}
.banner_image_2 .image_wrap_2 {
  top: 75px;
  right: -34%;
}
.banner_image_2 .image_wrap_3 {
  top: 26%;
  right: -42%;
}
.banner_image_2 .image_wrap_4 {
  top: 38%;
  left: -32%;
}
.service_item {
  border-radius: 8px;
  position: relative;
  padding: 30px 40px 40px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: #fff;
  border: 2px solid #f6f6f6;
  box-shadow: 0 1px 1px 0 rgba(31, 29, 13, 0.2);
}
.service_item:hover {
  z-index: 1;
  border-color: #1f1d0d;
  background-color: var(--primary-light);
  box-shadow: 0 20px 30px 0 rgba(31, 29, 13, 0.1);
}
.service_item:hover .item_icon {
  border-color: #1f1d0d;
}
.service_item:hover .item_icon img {
  transform: scale(0.7);
}
.service_item .item_icon {
  width: 66px;
  height: 66px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  border: 2px solid transparent;
  background-color: #fff;
}
.service_item .item_icon img {
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.service_item .item_title {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 11px;
}
.service_item p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 20px;
}
.service_item .info_list > li {
  font-size: 15px;
}
.service_item .info_list > li:not(:last-child) {
  margin-bottom: 6px;
}
.service_item .info_list i {
  font-size: 10px;
  margin: 5px 8px 0 0;
}
.course_list_wrap .course_list {
  margin: -10px;
}
.course_small_layout {
  padding: 10px;
  align-items: center;
  display: inline-flex;
  border-radius: 8px;
}
.course_small_layout .item_image {
  max-width: 120px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 8px;
}
.course_small_layout .item_image img {
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.course_small_layout .item_title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1f1d0d;
  margin-bottom: 10px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  font-family: Barlow, sans-serif;
}
.course_small_layout .item_title:hover {
  opacity: 0.8;
}
.course_small_layout .item_price {
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.course_small_layout .item_price del {
  opacity: 0.8;
  font-size: 14px;
  font-weight: 500;
  margin-right: 2px;
}
.course_small_layout .item_price b {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
}
.course_small_layout:hover {
  background-color: #f6f6f6;
}
.course_small_layout:hover .item_image img {
  transform: scale(1.08);
}
.course_small_layout:hover .item_title {
  text-decoration: underline;
}
.course_card {
  overflow: hidden;
  position: relative;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  padding: 28px 28px 32px;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #d2d2d2;
}
.course_card:hover {
  z-index: 1;
  border-color: #1f1d0d;
  transform: translate(-2px, -2px);
  box-shadow: 10px 10px 0 0 #1f1d0d;
}
.course_card .item_image {
  margin-bottom: 23px;
}
.course_card .item_image > a {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.course_card .item_image img {
  width: 100%;
  display: block;
  height: 245px;
  object-fit: cover;
}
.course_card .item_price .sale_price {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.course_card .item_price .remove_price {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.course_card .item_title {
  margin: 0;
  padding: 10px 0 24px;
}
.course_card .item_title > a {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  color: #1f1d0d;
}
.course_card .item_title > a:hover {
  opacity: 0.7;
}
.course_card.style_2 .item_image {
  margin: -28px -28px 23px;
}
.course_card.style_2 .item_image > a {
  border-radius: 0;
}
.course_card.list_layout {
  display: flex;
  align-items: center;
}
.course_card.list_layout:not(:last-child) {
  margin-bottom: 30px;
}
.course_card.list_layout .item_image {
  flex: 0 0 310px;
}
.course_card.list_layout .item_image,
.courses_archive_section .course_card.list_layout .item_image {
  margin: 0 30px 0 0;
}
.courses_archive_section .course_card .item_image {
  margin: -30px -30px 23px;
}
.filter_topbar {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
}
.filter_result {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.filter_buttons_list {
  margin: -6px;
}
.filter_buttons_list > li {
  padding: 6px;
}
.filter_buttons_list button {
  height: 44px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 19px;
  color: #1f1d0d;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: Barlow, sans-serif;
  border-radius: 8px;
  background-color: #f6f6f6;
  border: 2px solid #d2d2d2;
}
.filter_buttons_list button:hover {
  color: #fff;
  border-color: #1f1d0d;
  background-color: #1f1d0d;
}
.filter_buttons_list button i {
  margin-right: 6px;
}
.filter_buttons_list .form_item select {
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 30px 0 20px;
  text-transform: uppercase;
  font-family: Barlow, sans-serif;
  background-color: #f6f6f6;
}
.offCanvas_overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: saturate(180%) blur(8px);
  backdrop-filter: saturate(180%) blur(8px);
}
.offCanvas_overlay.active {
  display: block;
}
.offCanvas_close_btn {
  width: 40px;
  height: 40px;
  color: #1f1d0d;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border-radius: 8px;
  background-color: #f6f6f6;
}
.offCanvas_close_btn:hover {
  color: #fff;
  background-color: #1f1d0d;
}
.filter_offcanvas.position-fixed {
  top: 0;
  right: -320px;
  bottom: 0;
  z-index: 999;
  width: 300px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: #fff;
  box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.1);
}
.filter_offcanvas.position-fixed.active {
  right: 0;
}
.filter_offcanvas.position-fixed > .content_wrapper {
  height: 100%;
  overflow-y: scroll;
  padding: 110px 30px 50px;
}
.filter_offcanvas.position-fixed > .content_wrapper::-webkit-scrollbar {
  width: 0;
}
.filter_offcanvas.position-fixed .close_btn_wrap {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 30px;
  position: absolute;
  background-color: #fff;
}
.course_deals_section .shape_img_1 {
  top: -85px;
  left: -110px;
  max-width: 270px;
}
.course_deals_section .shape_img_2 {
  top: -46px;
  right: 158px;
  max-width: 102px;
}
.course_deals_section .shape_img_3 {
  right: -80px;
  bottom: -116px;
  max-width: 380px;
}
.popular_event_section {
  margin-bottom: 20px;
}
.popular_event_section .section_heading {
  padding-right: 100px;
}
.popular_event_section .shape_img_1 {
  top: -136px;
  left: -120px;
  max-width: 270px;
}
.popular_event_section .shape_img_2 {
  left: 44%;
  bottom: -46px;
  max-width: 270px;
}
.popular_event_section .shape_img_3 {
  top: 40%;
  right: -196px;
  max-width: 386px;
}
.popular_event_list {
  padding: 40px;
  margin-bottom: -140px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.09), 10px 10px 0 0 var(--primary-dark);
}
.popular_event_list .wrap_title {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 30px;
}
.popular_event_list ul > li {
  display: flex;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.popular_event_list ul > li:not(:last-child) {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d2d2d2;
}
.popular_event_list ul .column:nth-child(1) {
  flex: 0 0 100px;
  padding-right: 20px;
}
.popular_event_list ul .day {
  display: block;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
}
.popular_event_list ul .month {
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding: 5px 0 9px;
  text-transform: uppercase;
}
.popular_event_list ul .time {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.popular_event_list ul .event_title {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 12px;
}
.popular_event_list ul .event_name {
  color: #444;
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto, sans-serif;
}
.popular_event_list ul .event_name strong {
  font-weight: 500;
}
.popular_event_list ul .event_name small {
  font-size: 16px;
}
.event_card {
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #d2d2d2;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.event_card:hover {
  border-color: #1f1d0d;
  transform: translate(-2px, -2px);
  box-shadow: 10px 10px 0 0 #1f1d0d;
}
.event_card .item_image {
  display: block;
  overflow: hidden;
}
.event_card .item_content {
  padding: 30px;
}
.event_card .item_title {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 20px;
}
.event_card .item_title > a {
  color: #1f1d0d;
}
.event_card .item_title > a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
.event_card .meta_info_list > li {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #1f1d0d;
}

.advertisement_section {
  margin: 30px 0;
}
.advertisement_section .section_heading {
  padding-right: 70px;
}
.advertisement_section .images_group .col:nth-child(1) {
  margin-bottom: -45px;
}
.advertisement_section .images_group .col:nth-child(2) {
  margin-top: -45px;
}
.advertisement_section .images_group .image_wrap {
  overflow: hidden;
  border-radius: 8px;
}
.advertisement_section .images_group .image_wrap:not(:last-child) {
  margin-bottom: 30px;
}
.advertisement_section .images_group .shape_img_1 {
  left: -106px;
  bottom: 83px;
  max-width: 270px;
}
.advertisement_section .images_group .shape_img_2 {
  top: 73px;
  right: -138px;
  max-width: 102px;
}
.testimonial_carousel {
  margin: -15px;
}
.testimonial_carousel .carousel_item {
  padding: 15px;
  padding-bottom: 0px;
}
.testimonial_item {
  display: flex;
  padding: 30px;
  position: relative;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d2d2d2;
}
.testimonial_item .testimonial_image {
  margin: auto;
  flex: 0 0 170px;
  max-width: 170px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 8px;
}
.testimonial_item .rating_star {
  margin-bottom: 10px;
}
.testimonial_item .testimonial_title {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 10px;
}
.testimonial_item p {
  color: #29281e;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px;
}
.testimonial_item .testimonial_designation {
  color: #29281e;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 5px;
  font-family: Roboto, sans-serif;
}
.testimonial_item .testimonial_name {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 16px;
  text-transform: uppercase;
}
.testimonial_item .quote_icon {
  top: 30px;
  right: 30px;
  line-height: 1;
  font-size: 34px;
  position: absolute;
  text-shadow: 2px -2px 0 var(--primary-light);
}
.testimonial_item_2 {
  display: flex;
  align-items: center;
}
.testimonial_item_2 .testimonial_image {
  margin: 0 80px;
  flex: 0 0 520px;
  overflow: hidden;
  display: inline-flex;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  background-color: var(--primary-dark);
  background-position: center center;
}
.testimonial_item_2 .testimonial_image.image_widget {
  box-shadow: 15px 15px 0 0 var(--primary-dark);
}
.testimonial_item_2 .quote_icon {
  margin-bottom: 40px;
}
.testimonial_item_2 .testimonial_title {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: #1f1d0d;
  margin-bottom: 15px;
  font-family: Barlow, sans-serif;
}
.testimonial_item_2 p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #29281e;
  margin-bottom: 30px;
  font-family: Roboto, sans-serif;
}
.testimonial_item_2 .testimonial_admin {
  display: flex;
  align-items: center;
}
.testimonial_item_2 .admin_image {
  flex: 0 0 60px;
  overflow: hidden;
  margin-right: 15px;
  border-radius: 8px;
}
.testimonial_item_2 .testimonial_name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 5px;
  font-family: Roboto, sans-serif;
}
.testimonial_item_2 .testimonial_designation {
  display: block;
  font-size: 14px;
  line-height: 20px;
}
.newslatter_section {
  background-image: linear-gradient(0deg, #1f1d0d 120px, transparent 0);
}
.blog_small_group {
  margin: -10px;
}
.blog_small_group > li:not(:last-child) {
  margin-bottom: 2px;
}
.blog_small {
  padding: 10px;
  display: flex;
  max-width: 350px;
  align-items: center;
  border-radius: 8px;
}
.blog_small:hover {
  background-color: #f6f6f6;
}
.blog_small .item_image {
  flex: 0 0 120px;
  max-width: 120px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 8px;
}
.blog_small .item_image img {
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.blog_small .item_author {
  line-height: 1;
  display: block;
  color: #29281e;
  margin-bottom: 8px;
}
.blog_small .item_title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #1f1d0d;
  margin-bottom: 8px;
  font-family: Barlow, sans-serif;
}
.blog_small .item_post_date {
  line-height: 1;
  color: #c8c8c8;
  display: block;
  font-size: 14px;
}
.blog_small:hover {
  background-color: #f6f6f6;
}
.blog_small:hover .item_image img {
  transform: scale(1.08);
}
.blog_small:hover .item_title {
  text-decoration: underline;
}
.blog_item {
  position: relative;
  padding-bottom: 10px;
}
.blog_item .item_image {
  margin-bottom: 15px;
}
.blog_item .item_image > a {
  display: block;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}
.blog_item .item_image > a img {
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  width:100%;
  height: 280px;
  object-fit: cover;
}
.blog_item .item_image > a:hover img {
  transform: scale(1.08);
}
.blog_item .item_category_list {
  margin-bottom: 14px;
}
.blog_item:has(.item_image) .item_category_list {
  margin: 0;
  top: 20px;
  right: 20px;
  z-index: 2;
  position: absolute;
}
.blog_item .item_title {
  margin-bottom: 18px;
}
.blog_item .item_title a {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
  color: #1f1d0d;
}
.blog_item .item_title a:hover {
  opacity: 0.7;
}
.blog_item .meta_info_list {
  margin-bottom: 10px;
}
.blog_item .meta_info_list i {
  color: #1f1d0d;
}
.calltoaction_section {
  margin-bottom: 30px;
}
.calltoaction_section .section_heading .heading_description {
  max-width: 456px;
}
.calltoaction_section .shape_img_1 {
  left: 44%;
  bottom: -26px;
  max-width: 169px;
}
.calltoaction_section .shape_img_2 {
  top: -60px;
  right: 162px;
  max-width: 169px;
}
.calltoaction_section .shape_img_3 {
  top: 85px;
  left: -74px;
  max-width: 148px;
}
.calltoaction_section .shape_img_4 {
  left: 44%;
  bottom: -100px;
  max-width: 373px;
}
.calltoaction_section .shape_img_5 {
  top: -56px;
  right: -40px;
  max-width: 148px;
}
.calltoaction_section .info_list {
  margin-bottom: 40px;
}
.calltoaction_section .info_list i {
  font-size: 14px;
  margin: 6px 10px 0 0;
}
.calltoaction_section .boxed_wrapper {
  margin: auto;
  max-width: 1400px;
  padding: 100px 115px;
  background-color: #1f1d0d;
  border-radius: 8px;
}
.calltoaction_form {
  z-index: 1;
  padding: 40px;
  position: relative;
  margin-bottom: -150px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 10px 10px 0 0 var(--primary-dark);
}
.calltoaction_form .form_title {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 30px;
}
.calltoaction_form .form_item input,
.calltoaction_form .form_item textarea {
  border-width: 1px;
  background-color: #f6f6f6;
}
.calltoaction_form .form_item input:focus,
.calltoaction_form .form_item textarea:focus {
  background-color: #fff;
}
.calltoaction_form .form_item textarea {
  min-height: 30px;
}
.brands_logo_list {
  margin: -15px;
  justify-content: center;
}
.brands_logo_list > li {
  width: 25%;
  padding: 15px;
}
.brands_logo_list a {
  padding: 30px;
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #f6f6f6;
}
.brands_logo_list a img {
  max-width: 198px;
  max-height: 114px;
  mix-blend-mode: multiply;
}
.brands_logo_list a:hover {
  border-color: #1f1d0d;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 0 #1f1d0d;
}
.brands_logo_list.style_2 li {
  width: 16.666%;
}
.brands_logo_list.style_2 a {
  padding: 0;
  height: auto;
  border: none;
  background-color: transparent;
}
.brands_logo_list.style_2 a:hover {
  box-shadow: none;
  transform: unset;
}
.brands_logo_list.style_2 a img {
  max-height: 50px;
  max-width: 166px;
}
.mentor_item {
  padding: 30px;
  text-align: center;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d2d2d2;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.mentor_item .mentor_image {
  margin-bottom: 20px;
}
.mentor_item .mentor_image > a {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.mentor_item .mentor_image > a:before {
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  width: 100px;
  height: 100px;
  line-height: 1;
  font-size: 24px;
  content: "\f178";
  font-weight: 400;
  text-align: right;
  position: absolute;
  color: var(--primary-light);
  padding: 20px 18px 0 0;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: #1f1d0d;
  font-family: "Font Awesome 5 Pro";
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}
.mentor_item .mentor_name {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 10px;
}
.mentor_item .mentor_name > a {
  color: #1f1d0d;
}
.mentor_item .mentor_name > a:hover {
  opacity: 0.7;
}
.mentor_item .mentor_designation {
  margin-bottom: 15px;
}
.mentor_item:hover {
  z-index: 1;
  border-color: #1f1d0d;
  transform: translate(-2px, -2px);
  box-shadow: 10px 10px 0 0 #1f1d0d;
}
.mentor_item:hover .mentor_image > a:before {
  opacity: 1;
}
.mentor_item:hover .mentor_name > a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
.site_header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 29px 0;
  position: absolute;
  transition: padding 0.3s;
}
.site_header .container {
  max-width: 1430px;
}
.site_header.sticky {
  padding: 10px 0;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.96);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  box-shadow: 0 2px 6px 0 rgba(16, 18, 19, 0.08);
  transition: background-color 0.3s ease-in, padding 0.3s;
}
.main_menu {
  padding: 0;
}
.main_menu_list > li {
  margin: 0 1px;
  line-height: 1;
  font-size: 16px;
}
.main_menu_list > li > a {
  z-index: 1;
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  color: #1f1d0d;
  position: relative;
  align-items: center;
  display: inline-flex;
  padding: 9px 20px 8px;
  font-family: Roboto, sans-serif;
  border-radius: 8px;
}
.main_menu_list > li.active > a,
.main_menu_list > li:hover > a {
  color: #1f1d0d;
  background-color: #f6f6f6;
}
.main_menu_list > li:hover > a:after {
  transform: rotateX(-180deg);
}
.main_menu_list .dropdown > a {
  position: relative;
}
.main_menu_list .dropdown > a:after {
  float: right;
  line-height: 1;
  font-size: 14px;
  content: "\f107";
  font-weight: 400;
  margin-top: 2px;
  margin-left: 5px;
  margin-right:6px;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  font-family: "Font Awesome 5 Pro";
}
.main_menu_list .dropdown-menu {
  top: 100%;
  display: none;
  margin: 2px 0 0;
  padding: 14px 0;
  animation: unset;
  min-width: 210px;
  inset: 100% auto auto auto;
  border-radius: 8px;
  border: 2px solid #1f1d0d;
  box-shadow: -6px 6px 0 0 #101213;
}
.main_menu_list .dropdown-menu:before {
  top: -4px;
  height: 2px;
}
.main_menu_list .dropdown-menu > li:not(:last-child) {
  margin-bottom: 1px;
}
.main_menu_list .dropdown-menu > li > a {
  display: block;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  color: #29281e;
  position: relative;
  white-space: nowrap;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  padding: 14px 30px 12px 32px;
}
.main_menu_list .dropdown-menu > li > a:before {
  top: 19px;
  left: 20px;
  width: 4px;
  height: 4px;
  content: "";
  opacity: 0.7;
  position: absolute;
  border-radius: 100%;
  background-color: #1f1d0d;
}
.main_menu_list .dropdown-menu > li.active > a,
.main_menu_list .dropdown-menu > li:hover > a {
  background-color: var(--primary-light);
}
.main_menu_list .dropdown-menu .dropdown > a:after {
  margin-top: 1px;
  content: "\f105";
}
.main_menu_list .dropdown-menu .dropdown-menu {
  top: 0;
  margin: 0;
  left: 100%;
}
.main_menu_list .dropdown-menu.show {
  display: block;
}
.main_menu_list .dropdown-menu.show > li {
  animation: 0.2s dropDown;
}
.header_btns_group > li {
  margin-left: 14px;
}
.header_btns_group > li:first-child {
  display: none;
}
.header_btns_group .btn {
  padding: 0 26px;
}
.header_btns_group .btn span small {
  padding: 6px 0;
}
.site_header_1 + main {
  padding-top: 130px;
}
.site_header_2 .container {
  max-width: 1200px;
}
.site_footer {
  color: #c8c8c8;
  background-color: #20313d;
}
.site_footer .site_logo {
  margin-bottom: 35px;
}
.site_footer .footer_widget_area {
  padding: 130px 0 108px;
}
.site_footer p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 52px;
}
.site_footer .footer_widget_title {
  color: #fff;
}
.site_footer .page_list a {
  color: #fff;
}
.site_footer .page_list a:hover {
  color: var(--primary-light);
}
.site_footer .blog_small:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.site_footer .blog_small .item_image {
  flex: 0 0 70px;
  max-width: 70px;
  margin-right: 10px;
}
.site_footer .blog_small .item_author {
  display: none;
}
.site_footer .blog_small .item_title {
  font-size: 16px;
  color: #fff;
}
.footer_widget_title {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 35px;
}
.copyright_widget .copyright_text {
  padding: 40px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-top: 1px solid #444;
}
.copyright_widget .copyright_text a {
  color: #c8c8c8;
}
.copyright_widget .copyright_text a:hover {
  color: #fff;
}
[dir="rtl"] .about_us_section{
  background-image:unset;
}
.about_us_section{
 background-image:url(../public/assets/images/banner/page_banner_image.png);
}
.page_banner .content_wrapper {
  display: flex;
  min-height: 520px;
  align-items: center;
  padding: 70px 115px;
  background-size: 571px;
  background-repeat: no-repeat;
  background-color: #20313d;
  background-position: right bottom;
}
.page_banner .container {
  max-width: 1430px;
}
.page_banner .info_list,
.page_banner .meta_info_list {
  padding: 30px 0 40px;
}
.page_banner .btns_group {
  margin: -15px;
  display: flex;
  align-items: center;
}
.page_banner .btns_group > li {
  padding: 15px;
}
.page_banner .item_price {
  line-height: 1;
  font-family: Barlow, sans-serif;
}
.page_banner .item_price .remove_price {
  font-size: 24px;
  font-weight: 500;
  margin-right: 4px;
  color: #29281e;
}
.page_banner .item_price .sale_price {
  font-size: 45px;
  font-weight: 700;
  color: #1f1d0d;
}
.page_title {
  font-size: 65px;
  font-weight: 800;
  line-height: 68px;
  margin-bottom: 10px;
}

.page_description {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 40px;
  padding-right: 70px;
}
[dir="rtl"] .page_description{
  padding-right: 0;
}
.page_banner_image {
  margin-bottom: -120px;
  box-shadow: 20px 20px 0 0 var(--primary-dark);
}
.breadcrumb_nav {
  margin-bottom: 30px;
}
.breadcrumb_nav > li {
  line-height: 1;
  font-size: 13px;
  font-weight: 500;
  position: relative;
  color: white;
  text-transform: uppercase;
}
.breadcrumb_nav > li {
  margin-right: 12px;
  padding-right: 12px;
}
.breadcrumb_nav > li:after {
  top: 1px;
  right: -5px;
  opacity: 0.7;
  font-weight: 400;
  content: "\f101";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
}
.breadcrumb_nav > li > a {
  opacity: 0.7;
  display: block;
  color: white;
}
.breadcrumb_nav > li > a:hover {
  opacity: 1;
}
.sidebar .widget_title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 25px;
  color: #1f1d0d;
  position: relative;
  padding-right: 20px;
  font-family: Barlow, sans-serif;
}
.sidebar .widget_title:before {
  top: 2px;
  right: 0;
  content: "\f106";
  font-weight: 500;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
}
.sidebar .widget_title[aria-expanded="false"]:before {
  content: "\f107";
}
.sidebar .widget {
  padding-bottom: 25px;
  border-bottom: 1px solid #d2d2d2;
}
.sidebar .widget:not(:last-child) {
  margin-bottom: 25px;
}
.sidebar .widget .card {
  border: none;
  border-radius: 0;
  padding: 20px 0 0;
  background-color: transparent;
}
.sidebar .checkbox_item {
  margin-bottom: 10px;
}
.sidebar .checkbox_item input {
  top: 3px;
}
.sidebar .checkbox_item label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar .ratings_widget .checkbox_item i {
  color: var(--primary-dark);
}
.course_details_section .sidebar .widget {
  border: none;
  padding: 0;
}
.course_details_section .sidebar .widget:not(:last-child) {
  margin-bottom: 60px;
}
.course_details_section .sidebar .widget_title {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
}
.course_details_section .sidebar .widget_title:before {
  display: none;
}
.callbox_wrap {
  padding: 30px;
  margin-bottom: 60px;
  border-radius: 8px;
  border: 1px solid #1f1d0d;
  background-color: #fff;
}
.callbox_wrap .btn:not(:last-child) {
  margin-bottom: 20px;
}
.details_section .details_image.image_widget {
  box-shadow: -20px -20px 0 0 #1f1d0d;
}
.details_section .details_content .details_item_title {
  font-size: 45px;
  line-height: 50px;
  margin-bottom: 26px;
}
.details_section .details_content .mentor_name {
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 10px;
}
.details_section .details_content .details_info_title {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 20px;
}
.details_section .details_content .mentor_designation {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
}
.details_section .details_content .meta_info_list {
  margin-bottom: 30px;
}
.details_section .details_content p {
  margin-bottom: 30px;
}
.details_section .details_content hr {
  margin: 40px 0;
}
.course_info_card {
  padding: 30px;
  margin-bottom: 60px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 11px 11px 0 0 #1f1d0d;
}
.course_info_card .details_image {
  overflow: hidden;
  border-radius: 8px;
}
.course_info_card .details_image,
.course_info_card .item_price,
.course_info_card .meta_info_list {
  margin-bottom: 20px;
}
.course_info_card .item_price .sale_price {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  font-family: Barlow, sans-serif;
}
.course_info_card .btn {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}
.course_info_card .course_info_list {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.course_info_card .course_info_list > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.course_info_card .course_info_list > li:not(:last-child) {
  margin-bottom: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #d2d2d2;
}
.course_info_card .course_info_list i {
  width: 20px;
  margin-right: 5px;
  color: var(--primary-dark);
}
.course_details_section .sidebar .course_info_card,
.event_details_section .sidebar .course_info_card {
  z-index: 1;
  margin-top: -420px;
  position: relative;
}
.blog_details_section .details_image {
  margin-bottom: 20px;
}
.blog_details_section .details_content .meta_info_list {
  margin-bottom: 30px;
}
.blog_details_section .details_content .meta_info_list i {
  color: #1f1d0d;
}
.blog_details_section .comments_list_wrap {
  margin: 80px 0;
}
.blog_author {
  display: flex;
  padding: 30px;
  margin: 80px 0;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d2d2d2;
}
.blog_author .blog_author_image {
  flex: 0 0 140px;
  overflow: hidden;
  margin-right: 30px;
  border-radius: 8px;
}
.blog_author .author_name {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 10px;
}
.blog_author .author_designation {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #29281e;
  margin-bottom: 15px;
  font-family: Roboto, sans-serif;
}
.blog_author .blog_author_link {
  top: 0;
  right: 0;
  padding: 0 32px;
  position: absolute;
}
.blog_author .blog_author_link span small {
  padding: 8px 0;
}
.prevnext_post_wrap {
  display: flex;
  justify-content: space-between;
}
.prevnext_post_wrap .post_item {
  max-width: 300px;
}
.prevnext_post_wrap .post_item span {
  margin-bottom: 15px;
  align-items: center;
  display: inline-flex;
}
.prevnext_post_wrap .post_item span i {
  width: 20px;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  border-radius: 100%;
  margin: -1px 5px 0 0;
  color: var(--primary-light);
  background-color: #1f1d0d;
}
.prevnext_post_wrap .post_item span small {
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  color: #29281e;
  text-transform: uppercase;
}
.prevnext_post_wrap .post_item strong {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;
}
.prevnext_post_wrap .post_item strong:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.prevnext_post_wrap .post_item:last-child {
  text-align: right;
}
.prevnext_post_wrap .post_item:last-child span i {
  order: 1;
  margin: -1px 0 0 5px;
}
.event_speakers .mentor_item {
  padding: 10px 10px 18px;
}
.event_speakers .mentor_item .mentor_image {
  margin-bottom: 12px;
}
.event_speakers .mentor_item .mentor_name {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 6px;
}
.event_speakers .mentor_item .mentor_designation {
  font-size: 16px;
  font-weight: 400;
}


.contact_info_iconbox .item_icon {
  width: 62px;
  height: 62px;
  flex: 0 0 62px;
  color: #1f1d0d;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-dark);
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 4px 4px 0 0 #1f1d0d;
}
.contact_info_iconbox .item_title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
}
#gmap_canvas_iframe {
  width: 100%;
  height: 524px;
  border-radius: 8px;
  border: 3px solid var(--primary-dark);
}
.contact_form_section .shape_img_1 {
  right: 0;
  top: 150px;
  max-width: 102px;
}
.contact_form_section .shape_img_2 {
  left: 0;
  bottom: 130px;
  max-width: 72px;
}
.contact_form_section .shape_img_3 {
  top: -204px;
  left: -150px;
  max-width: 446px;
}
.contact_form_section .shape_img_4 {
  right: -270px;
  bottom: -160px;
  max-width: 643px;
}
.error_section {
  display: flex;
  padding: 55px 0;
  min-height: 100vh;
  align-items: center;
}
.error_section .error_image {
  max-width: 670px;
  margin: auto auto 20px;
}
.error_section .error_title {
  font-size: 60px;
  line-height: 66px;
  margin-bottom: 20px;
}
.error_section p {
  font-size: 16px;
  max-width: 500px;
  line-height: 24px;
  margin: auto auto 40px;
}
.error_section .shape_img_1 {
  right: 0;
  top: 150px;
  max-width: 102px;
}
.error_section .shape_img_2 {
  left: 0;
  bottom: 130px;
  max-width: 72px;
}
.error_section .shape_img_3 {
  top: -204px;
  left: -150px;
  max-width: 446px;
}
.error_section .shape_img_4 {
  right: -270px;
  bottom: -160px;
  max-width: 643px;
}
.register_heading {
  line-height: 1;
  font-size: 54px;
  margin: -60px 0 18px;
}
.register_heading_description {
  font-size: 18px;
  margin-bottom: 40px;
}
.register_form {
  z-index: 1;
  padding: 60px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #1f1d0d;
  box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.12), 10px 10px 0 0 #1f1d0d;
}
.register_form .form_item input,
.register_form .form_item textarea {
  border-color: #1f1d0d;
}
.register_form .icon_list > li:not(:last-child) {
  margin-bottom: 10px;
}
.register_form .icon_list i {
  float: left;
  font-size: 16px;
  font-style: normal;
  color: #1f1d0d;
  margin-right: 10px;
}
.register_form .icon_list span {
  display: table;
}
.register_form .note_text {
  font-size: 18px;
  color: #1f1d0d;
  font-style: italic;
}
.register_form hr {
  margin: 30px 0;
}
.register_form.signup_login_form .form_item {
  margin-bottom: 20px;
}
.register_form.signup_login_form .btn {
  width: 100%;
  display: block;
}
@media screen and (max-width: 1440px) {
  .intro_video_section .shape_img_3 {
    right: -160px;
    bottom: -180px;
    max-width: 360px;
  }
}
@media screen and (max-width: 1360px) {
  .page_banner .content_wrapper {
    padding: 70px;
  }
}
@media screen and (max-width: 1199px) {
  .hero_banner.style_1 .content_wrap {
    padding: 80px 40px;
  }
  .hero_banner .banner_big_title {
    font-size: 54px;
    line-height: 58px;
  }
  .banner_image_1 .satisfied_student {
    left: -150px;
    padding: 30px;
  }
  .banner_image_1 .satisfied_student .students_thumbnail span {
    width: 40px;
    height: 40px;
  }
  .banner_image_1 .satisfied_student .wrap_title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .form_item:has(.btn) input {
    flex: 0 0 340px;
  }
  .image_widget {
    box-shadow: -5px 5px 0 0 var(--primary-dark);
  }

  .calltoaction_form {
    box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 5px 5px 0 0 var(--primary-dark);
  }
  .details_section .details_image.image_widget {
    box-shadow: -5px -5px 0 0 #1f1d0d;
  }
  .course_info_card {
    box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.0894963), 5px 5px 0 0 #1f1d0d;
  }
  .testimonial_item_2 .testimonial_image.image_widget {
    box-shadow: 5px 5px 0 0 var(--primary-dark);
  }
  .popular_event_list {
    box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.09), 5px 5px 0 0 var(--primary-dark);
  }
  .details_section .details_image.image_widget {
    box-shadow: -5px -5px 0 0 #1f1d0d;
  }
  .register_form {
    box-shadow: 2px 2px 30px rgba(31, 29, 13, 0.12), 5px 5px 0 0 #1f1d0d;
  }
  .main_menu_list > li > a {
    padding: 9px 16px 8px;
  }
  .header_btns_group .btn {
    padding: 0 20px;
  }
  .advertisement_section {
    margin: 0;
    padding: 70px 0;
  }
  .advertisement_section .section_heading {
    padding-right: 0;
  }
  .advertisement_section .images_group .shape_img_1 {
    left: -60px;
    bottom: 83px;
    max-width: 180px;
  }
}
@media screen and (max-width: 1024px) {
  .main_menu_list > li > a {
    font-size: 16px;
    line-height: 22px;
    padding: 7px 14px 6px;
  }
  .header_btns_group .btn {
    padding: 0 18px;
  }
  .header_btns_group > li {
    margin-left: 8px;
  }
  .banner_image_1 {
    margin: 0;
  }
  .banner_image_1 .satisfied_student {
    left: 30px;
    padding: 20px;
    right:0px !important;
  }
  .banner_image_1 .shape_img_1 {
    left: -50px;
  }
  .banner_image_1 .shape_img_2 {
    right: -33px;
  }
  .service_item {
    padding: 30px 30px 35px;
  }
  .counter_item .counter_value {
    font-size: 48px;
    line-height: 54px;
  }
  .section_heading .heading_text {
    font-size: 38px;
    line-height: 42px;
  }
  .advertisement_section .images_group .shape_img_1 {
    left: -78px;
    max-width: 190px;
  }
  .testimonial_item .quote_icon {
    top: 15px;
    right: 15px;
    font-size: 28px;
  }
  .form_item:has(.btn) input {
    margin-right: 5px;
  }
  .hero_banner.style_2 .form_item:has(.btn) input {
    flex: 0 0 330px;
  }
  .testimonial_item_2 .testimonial_title {
    font-size: 24px;
  }
  .page_banner .form_item:has(.btn) {
    display: block;
  }
  .page_banner .form_item .btn {
    width: 100%;
    display: block;
    margin-top: 10px;
  }
  .page_banner .content_wrapper {
    background-size: 470px;
  }
  .brands_logo_list.style_2 li {
    width: auto;
  }
  .page_title {
    font-size: 48px;
    line-height: 54px;
  }
  .page_banner .info_list,
  .page_banner .meta_info_list {
    padding: 20px 0 30px;
  }
  .page_banner_image {
    margin: auto;
  }
  .error_section .error_image {
    max-width: 470px;
  }
  .error_section .error_title {
    font-size: 48px;
    line-height: 54px;
  }
  .error_section .shape_img_3 {
    top: -120px;
    left: -130px;
    max-width: 300px;
  }
  .error_section .shape_img_4 {
    right: -250px;
    max-width: 500px;
  }
  .error_section .shape_img_1 {
    top: 80px;
    right: 15px;
    max-width: 80px;
  }
  .error_section .shape_img_2 {
    left: 15px;
    bottom: 100px;
    max-width: 70px;
  }
  .register_form {
    padding: 40px;
  }
}
@media screen and (max-width: 991px) {
  .header_btns_group > li:first-child {
    display: inline-block;
  }
  .mobile_menu_btn {
    font-size: 24px;
    margin-right: 10px;
    transform: translateY(2px);
  }
  .container,
  .hero_banner.style_1 .container,
  .site_header .container {
    max-width: 730px;
  }
  .main_menu {
    left: 0;
    right: 0;
    top: 129px;
    z-index: 999;
    position: fixed;
  }
  .site_header.sticky {
    background-color: #fff;
  }
  .site_header.sticky .main_menu {
    top: 61px;
  }
  .main_menu_inner {
    background-color: #fff;
    border-bottom: 1px solid #d2d2d2;
  }
  .main_menu_list > li {
    width: 100%;
    margin: 1px 0;
    display: block;
  }
  .main_menu_list {
    margin: auto;
    padding: 15px;
    max-width: 730px;
  }
  .main_menu_list > li > a {
    width: 100%;
    display: block;
  }
  .main_menu_list .dropdown-menu {
    position: static;
    box-shadow: none;
  }
  .main_menu_list > li > a {
    font-size: 18px;
    line-height: 24px;
    padding: 12px 20px;
  }
  .main_menu_list .dropdown > a:after {
    font-size: 16px;
    margin-top: 5px;
  }
  .main_menu_list .dropdown-menu {
    padding: 8px 0;
  }
  .hero_banner {
    text-align: center;
  }
  [dir="rtl"] .hero_banner.style_1 .banner_description{
    padding-left:0px;
  }
  .hero_banner.style_1 .banner_description {
    padding-right: 0;
  }
  .hero_banner .banner_btns_group {
    justify-content: center;
  }
  .banner_image_1 {
    margin: 20px 0 0;
  }
  .hero_banner.style_1 {
    margin-bottom: 20px;
  }
  .section_space_lg {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .courses_info_section .image_widget,
  .expect_from_course .image_widget {
    margin-bottom: 30px;
  }
  .advertisement_section {
    margin: 0;
    padding: 90px 0;
  }
  .testimonial_section .section_heading {
    text-align: center;
  }
  .hero_banner.style_2 .banner_description {
    margin: auto auto 30px;
  }
  .hero_banner.style_2 .form_item {
    justify-content: center;
  }
  .hero_banner .tags_list {
    margin: auto;
    justify-content: center;
  }
  .hero_banner .banner_image_2 {
    max-width: 500px;
    margin: 20px auto auto;
  }
  .banner_image_2 .image_wrap_1 {
    margin-bottom: -780px;
  }
  .banner_image_2 .child_image .layer {
    max-width: 180px;
  }
  .banner_image_2 .image_wrap_2 {
    right: -15%;
  }
  .banner_image_2 .image_wrap_3 {
    top: 24%;
    right: -30%;
  }
  .banner_image_2 .image_wrap_4 {
    top: 30%;
    left: -24%;
  }
  .testimonial_item_2 {
    display: block;
  }
  .testimonial_item_2 .testimonial_image {
    margin: auto auto 40px;
  }
  .testimonial_item_2 .quote_icon {
    margin-bottom: 20px;
  }
  .calltoaction_form {
    margin: auto;
  }
  .calltoaction_section .shape_img_2 {
    top: -44px;
    right: 50px;
    max-width: 120px;
  }
  .calltoaction_section .shape_img_1 {
    left: 50px;
    bottom: -10px;
    max-width: 120px;
  }
  .brands_logo_list > li {
    width: 33.333%;
  }
  .brands_logo_list a img {
    max-width: 140px;
    max-height: 60px;
  }
  .form_item:has(.btn) {
    margin-bottom: 30px;
  }
  .hero_banner.style_2 .banner_big_title {
    margin: 0 0 20px;
  }
  .page_banner .content_wrapper {
    padding: 70px;
    text-align: center;
    background-size: 300px;
  }
  .about_us_section{
    background-image: unset;
  }
  .breadcrumb_nav {
    justify-content: center;
  }
  .page_description {
    padding: 0;
  }
  .popular_event_list {
    margin: auto;
  }
  .popular_event_section .shape_img_3 {
    right: -90px;
    max-width: 180px;
  }
  .popular_event_section .shape_img_1 {
    left: -90px;
    max-width: 180px;
  }
  .popular_event_section .shape_img_2 {
    bottom: 20px;
    max-width: 120px;
  }
  .sidebar {
    margin-top: 40px;
  }
  .contact_form_section .shape_img_4 {
    right: -200px;
    bottom: -100px;
    max-width: 470px;
  }
  .contact_form_section .shape_img_3 {
    top: -120px;
    left: -120px;
    max-width: 300px;
  }
  .page_banner .info_list,
  .page_banner .meta_info_list {
    justify-content: center;
  }
  .page_banner .btns_group {
    justify-content: center;
  }
  .service_item {
    padding: 25px 20px 30px;
  }
  .site_footer p {
    margin-bottom: 25px;
  }
  .footer_widget_title {
    line-height: 1;
    margin-bottom: 20px;
  }
  .site_footer .site_logo {
    margin-bottom: 25px;
  }
  .footer_widget {
    margin-bottom: 10px;
  }
  .site_footer .footer_widget_area {
    padding: 100px 0 80px;
  }
  
  .intro_video_section .shape_img_2 {
    left: -58px;
    max-width: 140px;
  }
  .intro_video_section .shape_img_3 {
    right: -110px;
    bottom: -120px;
    max-width: 230px;
  }
  .page_banner .content_wrapper {
    text-align: left;
  }
  .page_banner .unordered_list {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 767px) {
  [dir="rtl"] .header_btns_group{
    justify-content: start;
  }
  .header_btns_group {
    margin-left: -100px;
   
  }
  .header_btns_group .btn span small {
    font-size: 16px;
  }
  .advertisement_section .section_heading {
    padding: 0;
  }
  .popular_event_section .section_heading {
    padding: 0;
  }
  .btn_wrap {
    padding: 30px 0;
  }
  .section_heading {
    margin-bottom: 30px;
  }
  .intro_video_section .shape_img_3 {
    right: -100px;
    max-width: 180px;
  }
  .breadcrumb_nav > li {
    font-size: 14px;
    line-height: 18px;
  }
}
@media screen and (max-width: 680px) {
  .course_card.list_layout {
    display: block;
  }
  .course_card.list_layout .item_image,
  .courses_archive_section .course_card.list_layout .item_image {
    margin: 0 0 30px 0;
  }
  .calltoaction_section .boxed_wrapper {
    padding: 100px 15px;
  }
  .faq_section .section_heading.text-center,
  .newslatter_box .section_heading.text-center,
  .policy_section .section_heading.text-center,
  .process_section .section_heading.text-center {
    text-align: left !important;
  }
}
@media screen and (max-width: 575px) {
  .advertisement_section .images_group .col:nth-child(1),
  .advertisement_section .images_group .col:nth-child(2) {
    margin: 0;
  }
  .header_btns_group .btn span small {
    font-size: 14px;
    line-height: 18px;
  }
  .header_btns_group .btn {
    padding: 0 12px;
  }
  .header_btns_group .btn span small {
    padding: 4px 0;
  }
  .mobile_menu_btn {
    margin-right: 8px;
    color: #1f1d0d;
  }
  .mobile_menu_btn:hover {
    color: #1f1d0d;
  }
  
  .hero_banner .banner_image_2 {
    max-width: 400px;
  }
  .banner_image_2 .child_image .layer {
    max-width: 130px;
  }
  .banner_image_2 .image_wrap_1 {
    margin-bottom: -680px;
  }
  .banner_image_2 .image_wrap_4 {
    left: -10%;
  }
  .banner_image_2 .image_wrap_3 {
    right: -18%;
  }
  .banner_image_2 .image_wrap_2 {
    right: -10%;
  }
  .brands_logo_list > li {
    width: 50%;
  }
  .hero_banner.style_2 .form_item {
    display: block;
  }
  .hero_banner.style_2 .form_item .btn {
    width: 100%;
    display: block;
    margin-top: 10px;
  }
  .testimonial_item_2 .testimonial_image.image_widget {
    box-shadow: 10px 10px 0 0 var(--primary-dark);
  }
  .popular_event_list ul > li {
    display: block;
  }
  .popular_event_list ul .column:nth-child(1) {
    padding: 0 0 20px;
  }
  blockquote {
    display: block;
  }
  blockquote .blockquote_icon {
    margin: 0 0 10px;
  }
  .blog_author {
    margin: 50px 0;
    display: block;
  }
  .blog_author .blog_author_image {
    margin: 0 0 20px;
  }
  .blog_author .blog_author_link {
    position: static;
    margin: 20px 0 0;
  }
  .prevnext_post_wrap {
    display: block;
  }
  .prevnext_post_wrap .post_item {
    padding: 30px;
    display: block;
    max-width: 100%;
    border-radius: 6px;
    border: 2px solid #d2d2d2;
  }
  .prevnext_post_wrap .post_item:not(:last-child) {
    margin-bottom: 6px;
  }
  .filter_topbar {
    display: block;
  }
  .filter_result {
    margin: 0 0 24px;
  }
  .deals_countdown {
    display: block;
  }
  .deals_countdown .countdown_timer {
    width: 100%;
  }
  .deals_countdown .discount_value {
    width: 100%;
    margin: 15px 0 0;
  }
  .error_section .error_image {
    max-width: 370px;
  }
  .error_section p {
    font-size: 14px;
    line-height: 24px;
    margin: auto auto 30px;
  }
  .error_section .form_item:has(.btn) {
    display: block;
  }
  .error_section .form_item .btn {
    width: 100%;
    display: block;
    margin: 10px 0 0;
  }
  .details_section .details_content .mentor_name {
    font-size: 36px;
    line-height: 42px;
  }
  .details_section .details_content .details_item_title {
    line-height: 1;
    font-size: 40px;
    margin-bottom: 18px;
  }
  .accordion.style_2 .accordion-button {
    font-size: 18px;
    line-height: 22px;
  }
  .video_play_btn .icon {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    font-size: 14px;
  }
}
@media screen and (max-width: 425px) {
  .mobile_menu_btn {
    margin-right: 6px;
    font-size: 20px;
  }
  .header_btns_group > li {
    margin-left: 4px;
  }
  .header_btns_group .btn span small {
    font-size: 12px;
    line-height: 14px;
  }
  .site_header {
    padding: 20px 0;
  }
  .main_menu {
    top: 82px;
  }
  .site_header.sticky .main_menu {
    top: 62px;
  }
  .site_header_1 + main {
    padding-top: 82px;
  }
  .hero_banner.style_1 .content_wrap {
    padding: 80px 20px;
  }
  .hero_banner .banner_big_title {
    font-size: 42px;
    line-height: 44px;
  }
  .banner_image_1 .satisfied_student {
    left: 20px;
    padding: 15px;
  }
  .hero_banner.style_1 {
    margin-bottom: 0;
  }
  .testimonial_item {
    display: block;
  }
  .testimonial_item .testimonial_image {
    max-width: 100%;
    margin: auto auto 20px auto;
  }
  .course_card .item_price .sale_price {
    font-size: 22px;
    line-height: 28px;
  }
  .pricing_card {
    padding: 32px 30px 40px;
  }
  .pricing_card.bg_dark {
    padding: 62px 30px 60px;
  }
  .brands_logo_list a img {
    max-width: 120px;
    max-height: 50px;
  }
  .brands_logo_list a {
    height: 130px;
  }
  .calltoaction_form {
    padding: 30px 20px;
  }
  .page_title {
    font-size: 54px;
    line-height: 60px;
  }
  .page_banner .content_wrapper {
    padding: 70px 25px;
    background-size: 230px;
  }
  .iconbox_item .title_wrap {
    display: block;
  }
  .iconbox_item .title_wrap .item_icon {
    margin-bottom: 18px;
  }
  .iconbox_item .title_wrap {
    margin-bottom: 10px;
  }
  
  .comment_item .reply_btn {
    position: static;
    margin-top: 20px;
  }
  .remenber_forget {
    display: block !important;
  }
  .remenber_forget > .col {
    width: 100%;
  }
  .forget_password.text-end {
    text-align: left !important;
  }
  .section_heading .heading_text {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 18px;
  }
  .page_banner .content_wrapper {
    min-height: auto;
  }
  .section_space_lg {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 375px) {
  .course_card .item_content .d-flex {
    display: block !important;
  }
  .course_card .item_price {
    margin-top: 10px;
  }
  .banner_image_2 .image_wrap_1 {
    margin-bottom: -540px;
  }
  .banner_image_2 .child_image .layer {
    max-width: 100px;
  }
  .brands_logo_list > li {
    width: 100%;
  }
  .main_menu {
    top: 77px;
  }
  .site_header.sticky .main_menu {
    top: 56px;
  }
  .page_title {
    font-size: 36px;
    line-height: 40px;
  }
  .breadcrumb_nav {
    margin-bottom: 16px;
  }
  .page_banner .content_wrapper {
    margin: 0 -15px;
    padding: 60px 20px;
  }
  .page_banner .item_price .sale_price {
    font-size: 38px;
  }
  .page_banner .item_price .remove_price {
    font-size: 20px;
  }
  .page_banner .info_list,
  .page_banner .meta_info_list {
    padding: 14px 0 24px;
  }
}
@media screen and (max-width: 320px) {
  .main_menu {
    top: 73px;
  }
  .site_header.sticky .main_menu {
    top: 52px;
  }
}

.contactList{
  list-style-type:none;
  padding-right: 0px;

}
.contactList li{
  margin-bottom: 3px;

}
.contactList li a{
  color: white;
}
.contactList li a:hover{
  color:var(--primary-light);
}

/* .flow > * + * {
  margin-top: var(--flow-space, 1em);
} */

/* CARD COMPONENT */

.card {
  display: grid;
  place-items: center;

  /* max-width: 21.875rem; */
  height: 28.125rem;
  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}

.card > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  overflow: hidden;
}

.card__background {
  object-fit: cover;
  max-width: 100%;
  height: 100% !important;
}

.card__content {
  --flow-space: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  height:70%;
  padding: 12% 1.25rem 1.875rem;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.3) 10%,
    hsl(0, 0%, 0%) 100%
  );
  width:100%;
  overflow: hidden;
}
.card__description{
  color:white;
}
.card__content--container {
  --flow-space: 1.25rem;
}

.card__title {
  position: relative;
  width: fit-content;
  color:white;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
}

.card__title::after {
  content: "";
  position: absolute;
  height: 0.3125rem;
  width: calc(100% + 1.25rem);
  bottom: calc((1.25rem - 0.5rem) * -1);
  left: -1.25rem;
  background-color: hsl(46, 100%, 50%);
}

.card__button {
 
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */

}

.card__button:focus {
  outline: 2px solid black;
  outline-offset: -5px;
}

@media (any-hover: hover) and (any-pointer: fine) {

  .card__content {
    transform: translateY(62%);
    transition: transform 500ms ease-out;
    transition-delay: 500ms;
  }

  .card__title::after {
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 1000ms ease-in, transform 500ms ease-out;
    transition-delay: 500ms;
    transform-origin: right;
  }

  .card__background {
    transition: transform 300ms ease-in;
  }

  .card__content--container > :not(.card__title),
  .card__button {
    opacity: 0;
    transition: transform 300ms ease-out, opacity 300ms ease-out;
  }

  .card:hover,
  .card:focus-within {
    transform: scale(1.05);
    transition: transform 300ms ease-in;
  }

  .card:hover .card__content,
  .card:focus-within .card__content {
    transform: translateY(0);
    transition: transform 300ms ease-in;
  }

  .card:focus-within .card__content {
    transition-duration: 0ms;
  }

  .card:hover .card__background,
  .card:focus-within .card__background {
    transform: scale(1.3);
  }

  .card:hover .card__content--container > :not(.card__title),
  .card:hover .card__button,
  .card:focus-within .card__content--container > :not(.card__title),
  .card:focus-within .card__button {
    opacity: 1;
    transition: opacity 300ms ease-in;
    transition-delay: 500ms;
  }

  .card:hover .card__title::after,
  .card:focus-within .card__title::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: opacity 300ms ease-in, transform 300ms ease-in;
    transition-delay: 300ms;
  }
}

 /* about us */


.card-about {
  max-width: 550px;
  border: 0;
  width: 100%;
  margin-inline: auto;
  height:100%;
  max-height: 100%;
  
}

.card-about .container-card {
  position: relative;
  border: 1px solid #3d3b3b;
  height:100%;
  border-radius: 12px;
  padding: 25px;
  transition: 200ms;
  img {
    margin-bottom: 32px;
  }
}
.card-about:hover .container-card{
  border: 1px solid white;
}
.bg-green-box {
  position: relative;
}

.bg-green-box::after {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  content: "";
  z-index: -1;
  border-radius: 45px;
}

.bg-green-box::after {
  background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
}



.card-about .card-title {
  font-weight: 600;
  color: white;
  letter-spacing: -0.02em;
  line-height: 40px;
  font-style: normal;
  font-size: 28px;
  padding-bottom: 8px;
}

.card-about .card-description {
  font-weight: 400;
  line-height: 32px;

  /* max-width: 470px; */
}
.readMore
{
  position: absolute;
  bottom:10px;
  right:10px;
  font-size: 14px;
  font-weight: 500;
}
/* .meta_info_list a {
  color: #1f1d0d;
  font-weight: 500;
} */
.blog_small .item_author {
  line-height: 1;
  display: block;
  color: #29281e;
  margin-bottom: 8px;
  text-transform: lowercase;
  font-weight: 600;
  font-size:16px;
}
.editorClassName
{
  height: 275px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}
.btn_primary small{
  font-weight: 700 !important;
}

.btn.border_primary {
  color: #fff;
  border-color: var(--primary-light);
}
.btn.border_primary:hover {
  color: #1f1d0d;
  background-color: var(--primary-light);
}
.btn.border_primary:hover span small {
  color: #1f1d0d;
}
.bg_blue{
  background-color: #20313d;
}
.test_mustered{
  color:#eab01c;
}

